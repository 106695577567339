import { useEffect, useState } from "react";
import { styled } from "@mui/system";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useCart } from "../contexts/CartContext";

// MUI Components Import
import {
	Box,
	Grid,
	Typography,
	Button,
	IconButton,
	InputBase,
	FormControlLabel,
	Switch,
	TextField,
} from "@mui/material";

// Loader Import
import { MoonLoader } from "react-spinners";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components Import
import NavBar from "./components/Navbar";
import Footer from "./components/Footer/Footer";

// import icons
import EditIcon from "@mui/icons-material/EditCalendarOutlined";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "../contexts/LanguageContext";
import SelectAddressModal from "./components/Modals/SelectAddressModal";
import { baseUrl } from "../constants/api";
import {
	useGetCountryQuery,
	useGetSaudiCityQuery,
} from "../redux/api/utilApiSlice";
import SelectInputSearch from "../components/SelectInputSearch";
import { selectedUser } from "../redux/auth/authSlice";
import useTypedSelector from "../hooks/useTypedSelector";
import { selectedSetting } from "../redux/settings/settingSlice";
import { SubHeading, TertiaryHeading } from "../components/Heading";

const Checkout = () => {
	const token = window.localStorage.getItem("mp-user-token");
	const user = window.localStorage.getItem("username");
	const loginUser = useTypedSelector(selectedUser);
	const settings = useTypedSelector(selectedSetting);

	const navigate = useNavigate();

	const { cartProducts, calculateTotalPrice, actualTotal } =
		useCart();
	const totalPrice = calculateTotalPrice();
	const actualPrice = actualTotal();

	const [selectAddressModalOpen, setSelectAddressModalOpen] = useState(false);
	const [billingAddress, setBillingAddress] = useState();
	const [shippingAddress, setShippingAddress] = useState();
	const [modalFor, setModalFor] = useState(null);

	const [orderNotes, setOrderNotes] = useState("");
	const { translate, getLanguage, getDirection } = useTranslation();
	const language = getLanguage();
	const [updating, /*setUpdating*/] = useState(false);
	const [gift, setGift] = useState(false);
	const [giftAddress, setGiftAddress] = useState("");
	const [cityValue, setCityValue] = useState("");
	const [countryValue, setCountryValue] = useState("");
	const [landMark, setLandMark] = useState("");

	const orderProducts = cartProducts.map((product) => ({
		quantity: product.quantity,
		product: product?.product === true ? product.id : product.productID,
		variant: product?.product === true ? null : product.id,
		amount: product?.discounted_price === null ? product.vat_onlinePrice : product.discounted_price
	}));

	const processPayment = () => {
		if (!billingAddress) {
			toast.error("Please set an address.");
			return;
		}

		if (gift && !cityValue) {
			toast.error("Please select a city for gift address.");
			return;
		}
		if (gift && !giftAddress) {
			toast.error("Please set a gift address.");
			return;
		}

		const giftAddressData = {
			city: cityValue?.name,
			address: giftAddress,
			landmark: landMark,
		};

		const payload = {
			status: 1,
			total_price: Number(totalPrice?.toFixed(2)),
			tax: 0,
			shipping: 1,
			order_products: orderProducts,
			order_address: shippingAddress?.id,
			other_address: billingAddress?.id,
			notes: orderNotes,
			guest_address: gift ? JSON.stringify(giftAddressData) : "",
		}

		navigate(`/payment`, { state: { ordId: null, payload: payload } });

	};

	const fetchBillingAddress = () => {
		axios
			.get(`${baseUrl}addresses/type/billing`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Token ${token}`,
				},
			})
			.then((response) => {
				setBillingAddress(response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const fetchShippingAddress = () => {
		axios
			.get(`${baseUrl}addresses/type/shipping`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Token ${token}`,
				},
			})
			.then((response) => {
				setShippingAddress(response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleOpenModal = (modalFor) => {
		setSelectAddressModalOpen(true);
		const modalForKeyword = modalFor === "billing" ? "billing" : "shipping";
		setModalFor(modalForKeyword);
	};

	useEffect(() => {
		fetchBillingAddress();
		fetchShippingAddress();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// todo: GET COUNTRY API CALL
	const { data: getCountryList } = useGetCountryQuery({});

	// todo: GET SAUDI API CALL
	const { data: getSaudiCity } = useGetSaudiCityQuery({});

	useEffect(() => {
		const country = getCountryList?.find((ser) => ser.id === 194);
		setCountryValue(country);
	}, [getCountryList]);

	const isFirstOrder = loginUser?.is_ordered === false;

	return (
		<>
			<NavBar />
			<Wrapper dir={getDirection()}>
				<SelectAddressModal
					open={selectAddressModalOpen}
					setOpen={setSelectAddressModalOpen}
					setBillingAddress={setBillingAddress}
					setShippingAddress={setShippingAddress}
					modalFor={modalFor}
				/>
				<Grid
					container
					my={3}
					sx={{ display: "flex", gap: "50px", justifyContent: "center" }}
				>
					<Grid item md={6} sx={{ height: "auto", padding: "20px" }}>
						<Box
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<TertiaryHeading>{translate("checkout.to")}</TertiaryHeading>
							<AddButton
								size="medium"
								color="secondary"
								variant="contained"
								onClick={() => navigate("/profile/addresses")}
							>
								<AddIcon sx={{ color: "#fff", fontSize: "16px" }} />
								{translate("checkout.new")}
							</AddButton>
						</Box>

						<DataBox>
							<DataText>{user}</DataText>
						</DataBox>
						{billingAddress ? (
							<DataBox
								sx={{
									opacity: gift ? "0.5" : "1",
								}}
							>
								<CardHeading>{translate("checkout.billing")}</CardHeading>
								<Box
									sx={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										gap: "10px",
									}}
								>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
											gap: "5px",
											flexDirection: "row",
										}}
									>
										<Label>{billingAddress?.address_label_display}</Label>
										<EditIcon
											sx={{ color: "#000", size: "16px", cursor: "pointer" }}
											onClick={() => handleOpenModal("billing")}
										/>
									</Box>
									<Typography>{billingAddress?.fullname}</Typography>
									<Typography>{billingAddress?.phonenumber}</Typography>
									<Typography>
										{" "}
										{billingAddress?.address}, {billingAddress?.city},{" "}
										{billingAddress?.country}{" "}
									</Typography>
								</Box>
							</DataBox>
						) : null}
						{shippingAddress ? (
							<DataBox
								sx={{
									opacity: gift ? "0.5" : "1",
								}}
							>
								<CardHeading>{translate("checkout.shipping")}</CardHeading>
								<Box
									sx={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										gap: "10px",
									}}
								>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
											gap: "5px",
											flexDirection: "row",
										}}
									>
										<Label>{shippingAddress?.address_label_display}</Label>
										<EditIcon
											sx={{ color: "#000", size: "16px", cursor: "pointer" }}
											onClick={() => handleOpenModal("shipping")}
										/>
									</Box>
									<Typography>{shippingAddress?.fullname}</Typography>
									<Typography>{shippingAddress?.phonenumber}</Typography>
									<Typography>
										{" "}
										{shippingAddress?.address}, {shippingAddress?.city},{" "}
										{shippingAddress?.country}{" "}
									</Typography>
								</Box>
							</DataBox>
						) : null}
						<Grid item my={3}>
							{/* Gift card */}
							<Box>
								<FormControlLabel
									control={<Switch />}
									label={translate("checkout.giftOrder")}
									value={gift}
									onChange={() => setGift(!gift)}
									sx={{
										margin: "0",
									}}
								/>

								{gift && (
									<>
										<Box
											sx={{
												display: "flex",
												gap: "10px",
												margin: "20px 0 10px 0",
											}}
										>
											<Box
												sx={{
													width: "50%",
												}}
											>
												<SelectInputSearch
													name="country"
													options={getCountryList}
													placeholder="Select Country"
													value={countryValue}
													onChange={(e, value) => {
														setCountryValue(value);
													}}
													borderRadius="4px"
													height="40px"
													disabled={true}
												/>
											</Box>
											<Box
												sx={{
													width: "50%",
												}}
											>
												<SelectInputSearch
													name="city"
													options={getSaudiCity}
													placeholder="Select City"
													value={cityValue}
													onChange={(e, value) => {
														setCityValue(value);
													}}
													borderRadius="4px"
													height="40px"
												/>
											</Box>
										</Box>
										<Box
											sx={{
												display: "flex",
												gap: "10px",
												margin: "0px 0 10px 0",
											}}
										>
											<Box
												sx={{
													width: "50%",
												}}
											>
												<TextField
													id="outlined-basic"
													placeholder="Gift Address"
													size="small"
													color="success"
													type="text"
													variant="outlined"
													fullWidth
													value={giftAddress}
													onChange={(e) => setGiftAddress(e.target.value)}
													sx={{
														margin: "10px 0",
													}}
												/>
											</Box>
											<Box
												sx={{
													width: "50%",
												}}
											>
												<TextField
													id="outlined-basic"
													placeholder="Land Mark"
													size="small"
													color="success"
													type="text"
													variant="outlined"
													fullWidth
													value={landMark}
													onChange={(e) => setLandMark(e.target.value)}
													sx={{
														margin: "10px 0",
													}}
												/>
											</Box>
										</Box>
									</>
								)}
							</Box>

							<CardHeading>{translate("checkout.add")}</CardHeading>
							<Typography sx={{ fontWeight: "500", fontSize: "12px" }}>
								{translate("checkout.order")}
							</Typography>
							<StyledTextarea
								sx={{ padding: "12px" }}
								value={orderNotes}
								onChange={(e) => setOrderNotes(e.currentTarget.value)}
							/>
						</Grid>
						<Typography
							sx={{
								fontSize: "14px",
								fontWeight: "bolder",
								color: (theme) => theme.palette.primary.main,
								width: "fit-content",
								cursor: "pointer",
							}}
							onClick={() => navigate(-1)}
						>
							{translate("checkout.return")}
						</Typography>
					</Grid>
					<Grid
						item
						md={4}
						sx={{
							height: "fit-content",
							padding: "10px",
							borderRadius: "0px",
							boxShadow: "rgba(99, 99, 99, 0.1) 0px 2px 8px 0px",
						}}
					>
						<SubHeading
                            sx={{
                            color: "#191C1F",
                            padding: "0 0 20px 0",
                            }}
                        >
                            {translate("checkout.summary")}
                        </SubHeading>
						{cartProducts.length === 0 ? (
							<Typography sx={{ textAlign: "center", padding: "15px" }}>
								{translate("checkout.no")}
							</Typography>
						) : (
							cartProducts.map((product, index) => {
								const variant = product?.variants?.find(
									(v) => v.id === product?.id
								);
								return (
									<Box key={index}>
										<Box
											style={{
												display: "flex",
												gap: "8px",
												padding: "10px 10px",
												borderBottom: "1px solid #CBCBCB",
												flexDirection: "column",
											}}
										>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
												}}
											>
												<img
													src={
														variant?.variantImage || product?.images[0]?.image
													}
													alt="pic"
													style={{
														width: "55px",
														height: "55px",
														borderRadius: "5px",
														marginRight: "8px",
													}}
												/>
												<Box
													display={"flex"}
													flexDirection={"column"}
													gap={"5px"}
												>
													<Typography
														fontWeight={"bolder"}
														fontSize={"13px"}
														textAlign={"justify"}
													>
														{language === "ar"
															? product?.commons?.ar?.productName
															: product?.commons?.en?.productName}
													</Typography>
													<Typography fontWeight={"bold"} fontSize={"14px"}>
														{product.quantity} *{" "}
														<span style={{ color: "#2DA5F3" }}>
															{" "}
															{product.discounted_price === null
																? product.vat_onlinePrice
																: product.discounted_price}{" "}
															{translate(`navbar.SR`)}
														</span>{" "}
													</Typography>
												</Box>
											</Box>
											<Box
												sx={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
												}}
											>
												{variant?.variantCombination && (
													<>
														<Box>
															<Typography
																sx={{ fontSize: "14px", color: "#5F6C72" }}
															>
																Variant
															</Typography>
														</Box>
														<Box>
															<Button
																sx={{
																	border: "1px solid #00A9BF",
																	textTransform: "none",
																	padding: "2px 12px",
																	backgroundColor: "#00A9BF",
																	color: "#fff",
																	margin: "5px 0 10px 0",
																	cursor: "auto",
																	"&:hover": {
																		backgroundColor: "#00A9BF",
																		color: "#fff",
																	},
																}}
															>
																{variant?.variantCombination}
															</Button>
														</Box>
													</>
												)}
											</Box>
										</Box>
									</Box>
								);
							})
						)}
						<Box display={"flex"} flexDirection={"column"} padding={"0px"} >
							<DiscountWrapper>
								<Discountbar placeholder={translate("checkout.code")} />
								<IconButton
									type="button"
									sx={{
										borderRadius: "5px",
										width: "25%",
										border: (theme) =>
                                            `1px solid ${theme.palette.primary.main}`,
									}}
									aria-label="search"
								>
									<Typography
										sx={{
											fontSize: "14px",
                                            color: (theme) => theme.palette.primary.main,
											fontWeight: "600",
										}}
									>
										{translate("checkout.apply")}
									</Typography>
								</IconButton>
							</DiscountWrapper>
							<Box display={"flex"} justifyContent={"space-between"}>
								<Typography sx={{ fontSize: "14px", color: "#5F6C72" }}>
									{translate("checkout.sub")}
								</Typography>
								<Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
									{parseFloat(totalPrice || 0).toLocaleString(undefined, {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})}{" "}
									{translate(`navbar.SR`)}	
								</Typography>
							</Box>
							{/* <Box mt={'8px'} display={'flex'} justifyContent={'space-between'}>
                                <Typography sx={{ fontSize: "14px", color: '#5F6C72' }}>Shipping</Typography>
                                <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>free</Typography>
                            </Box> */}
							<Box mt={"8px"} display={"flex"} justifyContent={"space-between"}>
								<Typography sx={{ fontSize: "14px", color: "#5F6C72" }}>
									{translate("checkout.dis")}
								</Typography>
								<Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
									0
								</Typography>
							</Box>
							<Box mt={"8px"} display={"flex"} justifyContent={"space-between"}>
								<Typography sx={{ fontSize: "14px", color: "#5F6C72" }}>
									{translate("checkout.tax")}
								</Typography>
								<Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
									{settings?.vat_tax_active
										? `${Number(settings?.vat_tax)}%`
										: "0%"}
								</Typography>
							</Box>

							{/* Discount */}
							{isFirstOrder && (
								<>
									<Box
										sx={{
											background: "#029444",
											backgroundImage:
												"linear-gradient(315deg, #029444 0%, #d3d3d3 74%)",
											marginTop: "15px",
											padding: "5px 10px",
										}}
									>
										<Box
											sx={{
												color: "#fff",
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
											}}
										>
											<Typography
												sx={{
													fontSize: "14px",
													color: "#000",
													fontWeight: 700,
												}}
											>
												{translate(`cart.firstDiscount`)}
											</Typography>
											<Typography
												sx={{
													fontSize: "23px",
													fontWeight: "bold",
													color: "#fff",
												}}
											>
												{Number(settings?.first_time_discount || 0)}%{" "}
												<span
													style={{
														fontSize: "14px",
													}}
												>
													{translate(`cart.off`)}
												</span>
											</Typography>{" "}
										</Box>
									</Box>
								</>
							)}
							{/* Discount */}

							{isFirstOrder && (
								<Box
									mt={"20px"}
									display={"flex"}
									justifyContent={"space-between"}
									borderTop={"1px solid #DDDDDD"}
								>
									<Typography
										sx={{
											fontSize: "14px",
											color: "black",
											fontWeight: "bold",
											mt: "10px",
										}}
									>
										{translate("checkout.grand")}
									</Typography>
									<Typography
										sx={{
											fontSize: "14px",
											fontWeight: "bold",
											mt: "10px",
											color: "red",
											textDecoration: "line-through",
										}}
									>
										{actualPrice} {translate(`navbar.SR`)}
									</Typography>
								</Box>
							)}

							<Box
								mt={isFirstOrder ? "0" : "20px"}
								display={"flex"}
								justifyContent={"space-between"}
								borderTop={isFirstOrder ? "" : "1px solid #DDDDDD"}
							>
								<Typography
									sx={{
										fontSize: "14px",
										color: "black",
										fontWeight: "bold",
										mt: "10px",
									}}
								>
									{isFirstOrder ? (
										translate("cart.totalDiscount")
									) : (
										<>{translate("checkout.grand")}</>
									)}
								</Typography>
								<Typography
									sx={{ fontSize: "14px", fontWeight: "bold", mt: "10px" }}
								>
									{parseFloat(totalPrice || 0).toLocaleString(undefined, {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})}{" "}
									{translate(`navbar.SR`)}
								</Typography>
							</Box>
						</Box>
						<Box
							mt={2}
							mb={2}
							gap={2}
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								size="small"
								style={{
									textTransform: "none",
									padding: "4px 24px",
									width: "100%",
									height: "40px",
									borderRadius: "5px",
									fontWeight: "bolder",
									fontSize: "16px",
								}}
								color="secondary"
								variant="contained"
								onClick={() => {
									processPayment()
								}}
							>
								{updating ? (
									<>
										<MoonLoader color="#fff" size={20} />
									</>
								) : (
									translate("checkout.payment")
								)}
							</Button>
						</Box>

						<Box sx={{ margin: "0px 0 0 0", padding: "4px 24px" }}>
							<Typography
								sx={{
									fontSize: "12px",
									color: "red",
								}}
							>
								{translate("checkout.deliveryNote")}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Wrapper>
			<Footer />
		</>
	);
};

// Styled Components

const Wrapper = styled(Box)(({ theme }) => ({
	margin: "40px ",
	[theme.breakpoints.down("sm")]: {
		margin: "10px",
	},
}));

const AddButton = styled(Button)(({ theme }) => ({
	textTransform: "uppercase",
	width: "auto",
	height: "35px",
	borderRadius: "5px",
	fontWeight: "bolder",
	display: "flex",
	alignItems: "center",
	[theme.breakpoints.down("sm")]: {
		width: "40%",
	},
}));

const CardHeading = styled(Typography)(() => ({
	fontSize: "16px",
	fontWeight: "600",
	padding: "12px 2px ",
}));
const StyledTextarea = styled("textarea")(() => ({
	background: "#fff",
	borderRadius: "8px",
	fontSize: "16px",
	margin: "8px 0",
	height: "100px",
	width: "100%",
	border: "1px solid #C9CFD2",
}));

const Label = styled(Box)(() => ({
	fontSize: "10px",
	fontWeight: "700",
	background: "#a3a2a2",
	color: "#000",
	padding: "8px",
	borderRadius: "5px",
}));

const DataBox = styled(Box)(() => ({
	padding: "10px 15px",
	backgroundColor: "#F9F9F9",
	width: "auto",
	borderRadius: "10px",
	display: "flex",
	flexDirection: "column",
	margin: "15px 0",
	position: "relative",
}));

const DataText = styled(Typography)(() => ({
	fontSize: "13px",
	fontWeight: "400",
}));

const DiscountWrapper = styled(Box)(() => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-around",
	alignItems: "center",
	paddingTop: "20px",
	paddingBottom: "30px",
	gap: "10px"
}));

const Discountbar = styled(InputBase)(() => ({
	height: "40px",
	width: "95%",
	borderRadius: "5px",
	border: "1px solid #B4B4B4",
	padding: "10px",
}));

export default Checkout;
