// React Spinner Imports
import { MoonLoader } from "react-spinners";
// Hooks
import useTypedSelector from "../../hooks/useTypedSelector";
// Redux
import { selectedThemeSettings } from "../../redux/theme/themeSlice";

const MoonLoaderComponent = ({ color, loading, size = 20 }) => {
  const themeSetting = useTypedSelector(selectedThemeSettings);

  const loaderColor = color || themeSetting?.button_text_color;

  return <MoonLoader size={size} color={loaderColor} loading={loading} />;
};

export default MoonLoaderComponent;
