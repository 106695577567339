import { useEffect } from "react";

export const ApplePay = ({
	setCreatingSession,
	sessionId,
	amount,
	paymentMetaData,
	scriptURL,
}) => {
	useEffect(() => {
		if (!sessionId || !scriptURL) return;
		setCreatingSession(false);
		const divID = document.getElementById("apple-card-element");
		const script = document.createElement("script");
		script.src = scriptURL;
		script.async = true;
		script.onload = () => {
			divID.innerHTML = "";
			setCreatingSession(true);

			function payment(response) {
				// var sessionId = response.sessionId;
				// var cardBrand = response.cardBrand;
				// var cardIdentifier = response.cardIdentifier;
				console.log(response);
			}

			const config = {
				sessionId: sessionId,
				amount: amount,
				currencyCode: paymentMetaData.currency_code,
				countryCode: paymentMetaData.iso_code,
				cardViewId: "apple-card-element",
				callback: payment,
				style: {
					frameHeight: 51,
					button: {
						height: "50px",
						text: "Pay with", // Accepted values: ["pay", "book", "buy", "order", "subscribe"]
					},
				},
			};
			if (window.myFatoorahAP) {
				window.myFatoorahAP.init(config);
			}
			setCreatingSession(false);
		};
		document.body.appendChild(script);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionId]);

	return <div id="apple-card-element"></div>;
};
