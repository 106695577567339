import { Box, Container, Grid } from "@mui/material";

import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import parse from "html-react-parser";
import { useTranslation } from "../../../contexts/LanguageContext";
import { useGetSingleBlogQuery } from "../../../redux/api/cmsApiSlice";
import Loader from "../../../components/Loader/Loader";
import Navbar from "../../components/Navbar";
import { Heading } from "../../../components/Heading";
import { formatDate } from "../../../utils";
import Footer from "../../components/Footer/Footer";

const SingleBlog = () => {
    const { getLanguage, getDirection } = useTranslation();
    const language = getLanguage();
    const navigate = useNavigate();
    const { slug } = useParams();

    // todo: GET SINGLE BLOG
    const { data, isLoading } = useGetSingleBlogQuery(slug);

    // Helper function to manipulate HTML
    // const manipulateHTML = (htmlString) => {
    //     const parser = new DOMParser();
    //     const doc = parser.parseFromString(htmlString, "text/html");

    //     // Extract image and content
    //     const img = doc.querySelector("img")?.src;

    //     return { img };
    // };

    // const content = language === "en" ? data?.en_content : data?.ar_content;
    // const title = language === "en" ? data?.en_title : data?.ar_title;
    // const { img } = manipulateHTML(content);

    // const manipulateHTMLTwo = (htmlString) => {
    //     // Parse the HTML string
    //     const parser = new DOMParser();
    //     const doc = parser.parseFromString(htmlString, "text/html");

    //     // remove image from the content
    //     doc.querySelectorAll("img").forEach((img) => {
    //         img.remove();
    //     });

    //     // Example manipulation: Add a class to all <p> tags
    //     doc.querySelectorAll("p").forEach((p) => {
    //         p.classList.add("privacy-paragraph");
    //         p.style.fontSize = "16px";
    //         p.style.lineHeight = "24px";
    //     });

    //     // Convert the manipulated HTML back to string
    //     const manipulatedHTMLString = doc.body.innerHTML;

    //     return manipulatedHTMLString;
    // };

    const manipulateHTML = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
    
        const manipulatedHTMLString = doc.body.innerHTML;
    
        return manipulatedHTMLString;
      };
    
      const pageData = language === "en" ? data?.en_content : data?.ar_content;
      const title = language === "en" ? data?.en_title : data?.ar_title;
    
      const manipulatedHTML = manipulateHTML(pageData);
    
      const sectionOne = parse(manipulatedHTML, {
        replace: (domNode) => domNode,
      });

    return (
        <>
            {isLoading && <Loader />}
            <Navbar />
            <Wrapper dir={getDirection()}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                color: (theme) => theme.palette.primary.main,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "30px",
                                "@media (max-width: 576px)": {
                                    gap: "20px",
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    fontSize: "25px",
                                    width: "25px",
                                    height: "25px",
                                    cursor: "pointer",
                                }}
                                onClick={() => navigate("/blogs")}
                            >
                                <FaArrowLeftLong />
                            </Box>
                            <Heading>{title}</Heading>
                        </Box>
                        <Box
                            sx={{
                                color: "#969696",
                                margin: "40px 0",
                                fontSize: "16px",
                                fontWeight: "500",
                                textAlign: "center",
                                "@media (max-width: 576px)": {
                                    margin: "30px 0",
                                },
                            }}
                        >
                            <Box> Posted On {formatDate(data?.created_at)}</Box>
                        </Box>
                    </Grid>
                    {/* 2nd */}
                    <Grid item xs={12} md={2}></Grid>
                    <Grid item xs={12} md={8}>
                        <Box>{sectionOne}</Box>
                    </Grid>
                    <Grid item xs={12} md={2}></Grid>
                </Grid>
            </Wrapper>
            <Footer />
        </>
    );
};

const Wrapper = styled(Container)(() => ({
    padding: "50px 20px",
    margin: "30px auto",
}));

export default SingleBlog;
