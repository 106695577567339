import { useEffect, useState } from "react";

// MUI Components Import
import { styled, Box, Grid, Typography } from "@mui/material";

// Icons Import
import LocationIcon from "@mui/icons-material/LocationOnOutlined";
import PhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import { baseUrl } from "../../../constants/api";

// Assets Import
import axios from "axios";
import { useTranslation } from "../../../contexts/LanguageContext";
import BottomBar from "./BottomBar";
import FooterSections from "./FooterSections";
import { useDispatch } from "react-redux";
import { useFetchThemeSettingsQuery } from "../../../redux/api/themeApiSlice";
import { setThemeSettings } from "../../../redux/theme/themeSlice";

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: "#ae7766",
  //   background: "linear-gradient(130deg, #ae7766, #54bf80)",
  padding: "0px 20px",
  [theme.breakpoints.down("md")]: {
    padding: "10px",
  },
}));

const Footer = () => {

    const dispatch = useDispatch();

  const { translate, getLanguage, getDirection } = useTranslation();
  const language = getLanguage();

  const [aboutData, setAboutData] = useState([]);
  const [linksData, setLinksData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [socialsData, setSocialsData] = useState([]);
  const [paymentsData, setPaymentsData] = useState([]);
  const [partnersData, setPartnersData] = useState([]);

  const fetchAboutData = () => {
    axios
      .get(`${baseUrl}footer/about`)
      .then((response) => {
        setAboutData(response.data);
      })
      .catch(() => {});
  };

  const fetchLinksData = () => {
    axios
      .get(`${baseUrl}footer/link`)
      .then((response) => {
        setLinksData(response.data);
      })
      .catch(() => {});
  };

  const fetchContactData = () => {
    axios
      .get(`${baseUrl}footer/contact`)
      .then((response) => {
        setContactData(response.data);
      })
      .catch(() => {});
  };

  const fetchSocialsData = () => {
    axios
      .get(`${baseUrl}footer/social`)
      .then((response) => {
        setSocialsData(response.data);
      })
      .catch(() => {});
  };

  const fetchPaymentsData = () => {
    axios
      .get(`${baseUrl}footer/payment_link`)
      .then((response) => {
        setPaymentsData(response.data);
      })
      .catch(() => {});
  };

  const fetchPartnersData = () => {
    axios
      .get(`${baseUrl}footer/partner`)
      .then((response) => {
        setPartnersData(response.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchAboutData();
    fetchLinksData();
    fetchContactData();
    fetchSocialsData();
    fetchPaymentsData();
    fetchPartnersData();
  }, []);

  // todo: GET THEME SETTINGS
  const { data: themeData, refetch: refetchThemeSettings } =
    useFetchThemeSettingsQuery({});

  useEffect(() => {
    if (themeData) {
      dispatch(setThemeSettings(themeData));
      localStorage.setItem("theme", JSON.stringify(themeData));
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeData]);

  const textColor = themeData?.footer_text_color;

  useEffect(() => {
    refetchThemeSettings();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container dir={getDirection()}sx={{ backgroundColor: `${themeData?.footer_background}` }}>
        <Grid
          container
          padding={"20px 40px 80px 40px"}
          display={"flex"}
          dir={getDirection()}
        >
          <Grid xs={12} lg={2.4} md={4} item dir={getDirection()}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <img
                src={aboutData?.logo}
                alt="logo"
                style={{ height: "80px", width: "auto" }}
              />
              {aboutData.cr_number !== null ? (
                <Typography
                  sx={{
                    fontSize: "1.0rem",
                    fontWeight: "bolder",
                    color: textColor,
                  }}
                >
                  CR#: {aboutData.cr_number}
                </Typography>
              ) : null}
              <Typography
                sx={{ fontSize: "1.0rem", color: textColor, width: "85%" }}
              >
                {language === "ar"
                  ? aboutData?.arb_copyright
                  : aboutData?.eng_copyright}
              </Typography>
            </Box>
          </Grid>

          <FooterSections aboutData={aboutData} textColor={textColor} />
          <Grid xs={12} lg={2.4} md={4} item dir={getDirection()}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              mt={1}
              gap={"4px"}
              alignItems={"start"}
            >
              <Typography
                sx={{ fontSize: "1.4rem", fontWeight: "800", color: textColor }}
              >
                {translate("footer.usefullinks")}
              </Typography>
              {linksData?.map((link, index) => (
                <Box key={index}>
                  <a
                    href={link.link}
                    style={{ color: textColor }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {language === "ar" ? link?.arb_heading : link?.eng_heading}
                  </a>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid xs={12} lg={2.4} md={4} item dir={getDirection()}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              mt={1}
              gap={"8px"}
              alignItems={"start"}
            >
              <Typography
                sx={{
                  fontSize: "1.4rem",
                  fontWeight: "800",
                  color: textColor,
                  paddingBottom: "10px",
                }}
              >
                {translate("footer.storeInfomation")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "5px",
                }}
              >
                <LocationIcon sx={{ fontSize: "20px", color: textColor }} />
                <Typography
                  sx={{
                    fontSize: "1.0rem",
                    fontWeight: "bolder",
                    color: textColor,
                  }}
                >
                  {language === "ar"
                    ? contactData?.arb_address
                    : contactData?.eng_address}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "5px",
                }}
              >
                <PhoneIcon sx={{ fontSize: "20px", color:textColor }} />
                <Typography
                  sx={{
                    fontSize: "1.0rem",
                    fontWeight: "bolder",
                    color:textColor,
                  }}
                >
                  +{contactData?.phone}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "5px",
                }}
              >
                <EmailIcon sx={{ fontSize: "20px", color: textColor }} />
                <Typography
                  sx={{
                    fontSize: "1.0rem",
                    fontWeight: "bolder",
                    color: textColor,
                  }}
                >
                  {contactData?.email}
                </Typography>
              </Box>
            </Box>
            <ImageBox my={2} sx={{
                border: `.5px solid ${textColor}`,
              }}>
              <Text sx={{ color: textColor }}>{translate("footer.ConnectUs")}</Text>
              <ImageWrapper>
                {socialsData?.map((social, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: "40px",
                      height: "auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={social.icon}
                      style={{
                        width: "100%",
                        cursor: "pointer",
                        border: "none",
                        borderRadius: "5px",
                      }}
                      alt="Product"
                    />
                  </Box>
                ))}
              </ImageWrapper>
            </ImageBox>
          </Grid>
        </Grid>
      </Container>
      <BottomBar
        images={paymentsData}
        partnersData={partnersData}
        poweredBy={aboutData.powered_by}
      />
    </>
  );
};

const Text = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "700",
}));

const ImageBox = styled(Box)(({ theme }) => ({
  padding: "10px 20px",
  height: "auto",
  borderRadius: "5px",
  border: "1px solid #ae7766",
  [theme.breakpoints.down("sm")]: {
    width: "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
}));
const ImageWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  marginTop: "20px",
  gap: "10px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export default Footer;
