import { Box, Container } from "@mui/material";


function DiscountBanner({ banner }) {
  return (
    <Container>
        <Box
            sx={{
                width: "100%",
                height: "400px",
                margin: "44px auto !important",
            }}
        >
            <img
                src={banner}
                alt="banner"
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                }}
            />
        </Box>
    </Container>
  );
}

export default DiscountBanner;
