import { useEffect, useState } from "react";
import { Box, Container, Typography, styled } from "@mui/material";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import { useTranslation } from "../contexts/LanguageContext";
import { useGetDynamicPageQuery } from "../redux/api/cmsApiSlice";
import Loader from "../components/Loader/Loader";
import Navbar from "./components/Navbar";
import { Heading } from "../components/Heading";
import { formatDate } from "../utils";
import Footer from "./components/Footer/Footer";


function DynamicPage() {
    const { getLanguage, getDirection } = useTranslation();
    const language = getLanguage();

    const { page_id } = useParams();

    const [dynamicData, setDynamicData] = useState({});

    // todo: GET STATIC PAGES DATA API CALL
    const { data, isLoading } = useGetDynamicPageQuery(page_id);

    useEffect(() => {
        if (data) {
            setDynamicData(data);
        }
    }, [data]);

    const manipulateHTML = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
    
        // doc.querySelectorAll("img").forEach((img) => {
        //   // Create a new div element
        //   const wrapperDiv = document.createElement("div");
    
        //   // Set the styles for the wrapper div
        //   wrapperDiv.style.height = "400px";
        //   wrapperDiv.style.width = "100%";
        //   wrapperDiv.style.display = "flex";
        //   wrapperDiv.style.justifyContent = "center";
        //   wrapperDiv.style.alignItems = "center";
        //   wrapperDiv.style.overflow = "hidden";
        //   wrapperDiv.style.margin = "0 auto";
    
        //   // Set styles for the image
        //   img.style.maxHeight = "100%";
        //   img.style.maxWidth = "100%";
        //   img.style.objectFit = "contain";
    
        //   // Insert the image into the wrapper div
        //   img.parentNode.insertBefore(wrapperDiv, img);
        //   wrapperDiv.appendChild(img);
        // });
    
        // doc.querySelectorAll("p").forEach((p) => {
        //   p.classList.add("privacy-paragraph");
        //   p.style.fontSize = "16px";
        //   p.style.lineHeight = "24px";
        // });
    
        const manipulatedHTMLString = doc.body.innerHTML;
    
        return manipulatedHTMLString;
      };

    const pageData =
        language === "en" ? dynamicData?.en_content : dynamicData?.ar_content;

    const manipulatedHTML = manipulateHTML(pageData);

    const sectionOne = parse(manipulatedHTML, { replace: (domNode) => domNode });

    return (
        <>
            {isLoading && <Loader />}
            <Navbar />
            <Wrapper dir={getDirection()}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                    }}
                >
                    <Heading>
                        {language === "en" ? dynamicData?.en_title : dynamicData?.ar_title}
                    </Heading>
                    <Typography
                        sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#969696",
                        }}
                    >
                        {language === "en" ? "Last Update:" : "اخر تحديث:"}{" "}
                        {formatDate(dynamicData?.updated_at || new Date())}
                    </Typography>
                </Box>
                <DataWrapper>
                    <DataText>{sectionOne}</DataText>
                </DataWrapper>
            </Wrapper>
            <Footer />
        </>
    );
}

// Styled Components

const Wrapper = styled(Container)(() => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "30px",
    padding: "50px 20px",
    margin: "30px auto",
}));

const DataWrapper = styled(Box)(() => ({
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "start",
    // gap: "8px",
}));

const DataText = styled(Typography)({
    // fontSize: "14px",
    // fontWeight: "500",
    // color: "#000",
    // textAlign: "start",
});

export default DynamicPage;
