import { createTheme, ThemeProvider } from "@mui/material";
import RootComponent from "./Router";
import { CartProvider } from "./contexts/CartContext";
import { LanguageProvider } from "./contexts/LanguageContext";
import { ToastContainer } from "react-toastify";
import useTypedSelector from "./hooks/useTypedSelector";
import { selectedLanguage, selectedThemeSettings } from "./redux/theme/themeSlice";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import { LoaderProvider } from "./contexts/LoaderContext";

const App = () => {
    const themeSetting = useTypedSelector(selectedThemeSettings);
    const browserLanguage = useTypedSelector(selectedLanguage);

    const rtlCache = createCache({
        key: "muirtl",
        stylisPlugins: [prefixer, rtlPlugin],
    });

    const ltrCache = createCache({
        key: "mui",
    });
    const theme = createTheme({
        palette: {
            primary: {
                main: themeSetting?.button_color || "#f4f4f4",
                contrastText: themeSetting?.button_text_color || "#000",
            },
            secondary: {
                main: themeSetting?.button_color || "#f4f4f4",
                contrastText: themeSetting?.button_text_color || "#000",
            },
        },
        typography: {
            fontFamily: themeSetting?.font_family || "Poppins, sans-serif",
        },
    });
    return (
        <>
            <LoaderProvider>
                <LanguageProvider>
                    <CartProvider>
                        <ThemeProvider theme={theme}>
                            <ToastContainer hideProgressBar={true} autoClose={2000} />
                            <CacheProvider
                                value={browserLanguage === "ar" ? rtlCache : ltrCache}
                            >
                                <RootComponent />
                            </CacheProvider>
                        </ThemeProvider>
                    </CartProvider>
                </LanguageProvider>
            </LoaderProvider>
        </>
    );
};

export default App;
