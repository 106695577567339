// Redux Toolkit Imports
import { createSlice } from "@reduxjs/toolkit";

const getThemeSettings = () => {
    const localStorageItem = localStorage.getItem("theme");
    if (localStorageItem) {
        return JSON.parse(localStorageItem);
    } else {
        return [];
    }
};

const getLanguage = () => {
  const language = window.localStorage.getItem("language");

  return language ? language : "en";
};

const themeSlice = createSlice({
  name: "theme",

  initialState: {
    themeSettings: getThemeSettings(),
    language: getLanguage(),
  },

  reducers: {
    setThemeSettings(state, action) {
      state.themeSettings = action.payload;
    },

    setLanguageType(state, action) {
      state.language = action.payload;
    },
  },
});

export const { setThemeSettings, setLanguageType } = themeSlice.actions;
export default themeSlice.reducer;

export const selectedThemeSettings = (state) => state.theme.themeSettings;
export const selectedLanguage = (state) => state?.theme?.language;
