import { Typography } from "@mui/material";
import { useTranslation } from "../../../../contexts/LanguageContext";

export const STCPay = () => {

  const { translate } = useTranslation();

  return (
    <div>
      <Typography variant="body1">
        {translate("paycard.stcTagline")}
      </Typography>
    </div>
  );
};
