import { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setCartTime, setProducts } from "../redux/cart/cartSlice";
import useTypedSelector from "../hooks/useTypedSelector";
import { selectedSetting } from "../redux/settings/settingSlice";
import { selectedUser } from "../redux/auth/authSlice";

const CartContext = createContext();

export function CartProvider({ children }) {
	const dispatch = useDispatch();
	const [cartProducts, setCartProducts] = useState([]);
	const settings = useTypedSelector(selectedSetting);
	const loginUser = useTypedSelector(selectedUser);

	useEffect(() => {
		const storedProducts = localStorage.getItem("cartProducts");
		if (storedProducts) {
			setCartProducts(JSON.parse(storedProducts));
			dispatch(setProducts(JSON.parse(storedProducts)));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addToCart = (product) => {
		const now = new Date();
		localStorage.setItem("cartTime", JSON.stringify(now));
		dispatch(setCartTime(now));

		const existingProduct = cartProducts.find((p) => p.id === product.id);

		if (existingProduct) {
			const updatedCartProducts = cartProducts.map((p) => {
				if (p.id === product.id) {
					return { ...p, quantity: p.quantity + 1 };
				}
				return p;
			});
			setCartProducts(updatedCartProducts);
			dispatch(setProducts(updatedCartProducts));
			localStorage.setItem("cartProducts", JSON.stringify(updatedCartProducts));
		} else {
			setCartProducts([...cartProducts, { ...product, quantity: 1 }]);
			dispatch(setProducts([...cartProducts, { ...product, quantity: 1 }]));
			localStorage.setItem(
				"cartProducts",
				JSON.stringify([...cartProducts, { ...product, quantity: 1 }])
			);
		}
	};

	const isOrderLimitExceeded = (productId) => {
		const product = cartProducts.find((p) => p.id === productId);
		return (
			product && product.orderLimit && product.quantity >= product.orderLimit
		);
	};

	const incrementById = (id) => {
		const now = new Date();
		localStorage.setItem("cartTime", JSON.stringify(now));
		dispatch(setCartTime(now));

		const updatedCartProducts = cartProducts.map((product) => {
			if (product.id === id) {
				return { ...product, quantity: product.quantity + 1 };
			}
			return product;
		});

		setCartProducts(updatedCartProducts);
		dispatch(setProducts(updatedCartProducts));
		localStorage.setItem("cartProducts", JSON.stringify(updatedCartProducts));
	};

	const decrementById = (id) => {
		const now = new Date();
		localStorage.setItem("cartTime", JSON.stringify(now));
		dispatch(setCartTime(now));

		const updatedCartProducts = cartProducts
			.map((product) => {
				if (product.id === id) {
					const updatedQuantity = product.quantity - 1;
					if (updatedQuantity <= 0) {
						return null;
					} else {
						return { ...product, quantity: updatedQuantity };
					}
				}
				return product;
			})
			.filter((product) => product !== null);

		setCartProducts(updatedCartProducts);
		dispatch(setProducts(updatedCartProducts));
		localStorage.setItem("cartProducts", JSON.stringify(updatedCartProducts));

		if (updatedCartProducts.length === 0) {
			localStorage.removeItem("cartTime");
			dispatch(setCartTime(null));
		}
	};

	const removeFromCart = (id) => {
		const updatedCartProducts = cartProducts.filter(
			(product) => product.id !== id
		);

		setCartProducts(updatedCartProducts);
		dispatch(setProducts(updatedCartProducts));
		localStorage.setItem("cartProducts", JSON.stringify(updatedCartProducts));
	};

	// const calculateTotalPrice = () => {
	//   return cartProducts.reduce((total, product) => {
	//     const { quantity, discounted_price, vat_onlinePrice } = product;
	//     return total + quantity * (discounted_price ?? vat_onlinePrice);
	//   }, 0);
	// };

	const calculateTotalPrice = () => {
		let total = cartProducts.reduce((sum, product) => {
			const { quantity, discounted_price, vat_onlinePrice } = product;
			return sum + quantity * (discounted_price ?? vat_onlinePrice);
		}, 0);

		// Apply first-time discount only if the user has not claimed it
		if (loginUser?.is_ordered === false && settings?.first_time_discount) {
			const discount = Number(settings.first_time_discount);
			total = total * (1 - discount / 100);
		}

		return total;
	};

	const actualTotal = () => {
		return cartProducts.reduce((total, product) => {
			const { quantity, discounted_price, vat_onlinePrice } = product;
			return total + quantity * (discounted_price ?? vat_onlinePrice);
		}, 0);
	};

	const emptyCart = () => {
		setCartProducts([]);
		dispatch(setProducts([]));
		localStorage.removeItem("cartTime");
		dispatch(setCartTime(null));
		localStorage.removeItem("cartProducts");
	};

	return (
		<CartContext.Provider
			value={{
				cartProducts,
				addToCart,
				incrementById,
				isOrderLimitExceeded,
				decrementById,
				removeFromCart,
				calculateTotalPrice,
				actualTotal,
				emptyCart,
			}}
		>
			{children}
		</CartContext.Provider>
	);
}

CartProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export function useCart() {
	return useContext(CartContext);
}
