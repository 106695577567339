import { useEffect } from "react";
import { useTranslation } from "../../../../contexts/LanguageContext";

export const AmeCard = ({
	setCreatingSession,
	sessionId,
	countryCode,
	scriptURL,
}) => {

	const { translate, getDirection } = useTranslation();


	useEffect(() => {
		const direction = getDirection();
		const divID = document.getElementById("ae-card-element");
		divID.innerHTML = "Please try again later.";
		if (!sessionId || !scriptURL) return;
		setCreatingSession(false);
		const script = document.createElement("script");
		script.src = scriptURL;
		script.async = true;
		script.onload = () => {
			divID.innerHTML = "";
			setCreatingSession(true);
			const config = {
				countryCode: countryCode,
				sessionId: sessionId,
				cardViewId: "ae-card-element",
				supportedNetworks: "ae",
				callback: (response) => {
					console.log(response);
				},
				style: {
					hideCardIcons: false,
					direction: direction,
					cardHeight: 130,
					tokenHeight: 130,
					input: {
						color: "black",
						fontSize: "17px",
						fontFamily: "sans-serif",
						inputHeight: "50px",
						inputMargin: "10px",
						borderColor: "c7c7c7",
						borderWidth: "1px",
						borderRadius: "8px",
						boxShadow: "",
						placeHolder: {
							holderName: `${translate("paycard.holderName")}`,
							cardNumber: `${translate("paycard.cardNumber")}`,
							expiryDate: `${translate("paycard.expiryDate")}`,
							securityCode: `${translate("paycard.securityCode")}`,
						},
					},
					text: {
						saveCard: `${translate("paycard.saveCard")}`,
						addCard: `${translate("paycard.addCard")}`,
						deleteAlert: {
							title: "Delete",
							message: "Test",
							confirm: "yes",
							cancel: "no",
						},
					},
					label: {
						display: false,
						color: "black",
						fontSize: "13px",
						fontWeight: "normal",
						fontFamily: "sans-serif",
						text: {
							holderName: "Card Holder Name",
							cardNumber: "Card Number",
							expiryDate: "Expiry Date",
							securityCode: "Security Code",
						},
					},
					error: {
						borderColor: "red",
						borderRadius: "8px",
						boxShadow: "0px",
					},
				},
			};
			if (window.myFatoorah) {
				window.myFatoorah.init(config);
			}
			const iFrame = document.querySelector("[name='iframeCardView']");
			if (iFrame) {
				iFrame.style.width = "100%";
				iFrame.style.height = "300px";
			}
			setCreatingSession(false);
		};
		document.body.appendChild(script);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionId]);

	return <div id="ae-card-element"></div>;
};
