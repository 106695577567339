import { useState } from "react";
import axios from "axios";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import CircularProgress from "@mui/material/CircularProgress";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useDispatch } from "react-redux";
// Import the images
import CardsImgs from "../../../assets/cards.webp";
import MadaImage from "../../../assets/mada.jpg";
import EWallet from "../../../assets/ewallet.png";
import stc from "../../../assets/stcpay.png";
import { setUser } from "../../../redux/auth/authSlice";
import CartIcon from '@mui/icons-material/ShoppingCartOutlined';

// Components Import
import { Box, Grid, Typography, styled, Button } from "@mui/material";
import { useTranslation } from "../../../contexts/LanguageContext";

// import icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PaymentIcon from "@mui/icons-material/Payment";

import { baseUrl } from "../../../constants/api";
import { toast } from "react-toastify";

// Payment Methods
import { PayCard } from "./PaymentMethods/PayCard";
import { MadaCard } from "./PaymentMethods/MadaCard";
import { AmeCard } from "./PaymentMethods/AmexCard";
import { GooglePay } from "./PaymentMethods/GooglePay";
import { ApplePay } from "./PaymentMethods/ApplePay";
import { STCPay } from "./PaymentMethods/STCPay";
import { useLocation, useNavigate } from "react-router-dom";
import { useCart } from "../../../contexts/CartContext";
import { MoonLoader } from "react-spinners";

function MyFatoorahGateway() {
  const { cartProducts, emptyCart } = useCart();
  const { translate, getDirection } = useTranslation();
  const token = window.localStorage.getItem("mp-user-token");
  const location = useLocation()
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);

  // Payment Methods Related Variables
  const [cardExpanded, setCardExpanded] = useState(false);
  const [eWalletExpanded, setEWalletExpanded] = useState(false);
  const [stcExpanded, setSTCExpanded] = useState(false);
  const [creatingSession, setCreatingSession] = useState(false);
  const [sessionID, setSessionID] = useState(null);
  const [scriptURL, setScriptURL] = useState(null);
  const [payingCard, setPayingCard] = useState(false);
  const [payingSTCPay, setPayingSTCPay] = useState(false);
  const [paymentMetaData, setPaymentMetaData] = useState({});
  const [orderId, setOrderId] = useState(location?.state?.ordId)
  const [payload, /*setPayload*/] = useState(location?.state?.payload)

  const placeOrder = async () => {
    setLoading(true)
    const response = await axios.post(`${baseUrl}order`, payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    )

    if (response.status === 201) {
      const data = await response.data;
      const loggedInUser = JSON.parse(localStorage.getItem("user"));
  
      const updatedUser = {
        ...loggedInUser,
        metadata: {
          ...loggedInUser.metadata,
          is_ordered: data?.is_ordered,
        },
      };
  
      localStorage.setItem("user", JSON.stringify(updatedUser));
      dispatch(setUser(updatedUser));
      setOrderId(data?.order_id)
      emptyCart();
        setLoading(false)

      navigate(`/profile/orderdetail/${data?.order_id}`);

      return data?.order_id;
    } else {
        return null
    }
  }

  const initSession = (type) => {
    setCreatingSession(true);
    axios
      .get(`${baseUrl}fatoorah/init-session/${type}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setCreatingSession(false);
        setSessionID(res?.data?.session?.Data?.SessionId);
        setScriptURL(res?.data?.script);
        setPaymentMetaData(res?.data?.metadata);
      })
      .catch((err) => {
        console.log(err);
        setCreatingSession(false);
        toast.error("Failed to create payment session");
      });
  };
  
  const executePayment = async (
    orderId,
    sessionId,
    sessionCallback,
    paymentId,
    loaderCallback
  ) => {
    loaderCallback(true);
  
    const executePaymentRequest = async (currentOrderId) => {
      const paymentURL = `${baseUrl}fatoorah/execute-payment`;
      try {
        const response = await axios.post(
          paymentURL,
          {
            sessionId: sessionId,
            orderId: currentOrderId,
            paymentId: paymentId || null,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );
  
        const { data } = response;
        openPaymentWindow(data?.Data?.PaymentURL, currentOrderId);
  
      } catch (err) {
        console.log(err);
        toast.error("Failed to process payment");
        loaderCallback(false);
      }
    };
  
    const openPaymentWindow = (url, currentOrderId) => {
      const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=640,height=640,left=100,top=100`;
      const paymentWindow = window.open(url, "3D Secure Verification", params);
  
      const checkWindowClosed = setInterval(() => {
        if (paymentWindow.closed) {
          clearInterval(checkWindowClosed);
          navigate(`/profile/orderdetail/${currentOrderId}`);
          loaderCallback(false);
        }
      }, 1000);
    };
  
    if ((orderId === null || orderId === undefined) && cartProducts.length > 0) {
      const newOrderId = await placeOrder();
      await executePaymentRequest(newOrderId);
    } else {
      await executePaymentRequest(orderId);
    }
  };

  const payUsingCard = () => {
    if (!sessionID) {
      toast.error("Failed to create payment session");
      return;
    }
    setPayingCard(true);
    window.myFatoorah.submit();
    setTimeout(() => {
      executePayment(orderId, sessionID, initSession, null, setPayingCard);
    }, 2000);
  };

  const payUsingSTCPay = () => {
    if (!sessionID) {
      toast.error("Failed to create payment session");
      return;
    }
    executePayment(orderId, sessionID, initSession, 14, setPayingSTCPay);
  };

  const [value, setValue] = useState("1");
  const [payValue, setPayValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlePayValueChange = (event, newValue) => {
    setPayValue(newValue);
    if((newValue === '1') && (eWalletExpanded)){
      initSession("gpay");
      setCardExpanded(false);
      setSTCExpanded(false);
    }
    else if ((newValue === '2') && (eWalletExpanded)){
      initSession("apple");
      setCardExpanded(false);
      setSTCExpanded(false);
    }
  };

  return (
    <>
      <Wrapper dir={getDirection()}>
        <Grid
          container
          my={3}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Grid item md={7} sx={{ height: "auto", padding: "20px" }}>
            <Box
              sx={{
                border: " 1px solid #DDDDDD",
                marginBottom: "10px",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              <CardHeading>{translate("checkout.option")}</CardHeading>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CardBox>
                  <PaymentMethod
                    expanded={cardExpanded}
                    onChange={(event, isExpanded) => {
                      if (isExpanded) {
                        initSession("card");
                        setEWalletExpanded(false);
                        setSTCExpanded(false);
                      }
                      setCardExpanded(isExpanded);
                    }}
                  >
                    <PaymentType
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <PaymentMethodTitle>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {translate("checkout.card")}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <img
                            style={{ height: "20px" }}
                            src={CardsImgs}
                            alt=""
                          />
                          <img
                            style={{ height: "20px" }}
                            src={MadaImage}
                            alt=""
                          />
                        </Box>
                      </PaymentMethodTitle>
                    </PaymentType>
                    <PaymentContainer>
                      {creatingSession ? (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <CircularProgress size={20} />
                        </Box>
                      ) : (
                        <Box sx={{ height: "300px" }}>
                          <TabContext value={value}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <TabList
                                onChange={handleChange}
                                aria-label="Card Payment Types"
                              >
                                <Tab
                                  sx={
                                    value === "1"
                                      ? { fontWeight: "bolder" }
                                      : null
                                  }
                                  label={translate("paycard.vmC")}
                                  value="1"
                                />
                                <Tab
                                  sx={
                                    value === "2"
                                      ? { fontWeight: "bolder" }
                                      : null
                                  }
                                  label={translate("paycard.mADA")}
                                  value="2"
                                />
                                <Tab
                                  sx={
                                    value === "3"
                                      ? { fontWeight: "bolder" }
                                      : null
                                  }
                                  label={translate("paycard.aMEX")}
                                  value="3"
                                />
                              </TabList>
                            </Box>
                            <TabPanel value="1">
                              <PayCard
                                setCreatingSession={setCreatingSession}
                                sessionId={sessionID}
                                countryCode="KWT"
                                scriptURL={scriptURL}
                              />
                            </TabPanel>
                            <TabPanel value="2">
                              <MadaCard
                                setCreatingSession={setCreatingSession}
                                sessionId={sessionID}
                                countryCode="KWT"
                                scriptURL={scriptURL}
                              />
                            </TabPanel>
                            <TabPanel value="3">
                              <AmeCard
                                setCreatingSession={setCreatingSession}
                                sessionId={sessionID}
                                countryCode="KWT"
                                scriptURL={scriptURL}
                              />
                            </TabPanel>
                          </TabContext>
                        </Box>
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <PayButton
                          size="small"
                          color="primary"
                          variant="contained"
                          disableElevation
                          onClick={() => {
                            payUsingCard();
                          }}
                        >
                          {payingCard ? (
                            <CircularProgress
                              sx={{ color: "#fff" }}
                              size={20}
                            />
                          ) : (
                            <>
                              <PaymentIcon /> <ButtonText>{translate("paycard.payNow")}</ButtonText>
                            </>
                          )}
                        </PayButton>
                      </Box>
                    </PaymentContainer>
                  </PaymentMethod>
                </CardBox>
                <CardBox>
                  <PaymentMethod
                    expanded={eWalletExpanded}
                    onChange={(event, isExpanded) => {
                      if (isExpanded) {
                        initSession("gpay");
                        setCardExpanded(false);
                        setSTCExpanded(false);
                      }
                      setEWalletExpanded(isExpanded);
                    }}
                  >
                    <PaymentType
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <PaymentMethodTitle>
                        <Typography sx={{ fontWeight: "bold" }}>
                        {translate("checkout.e-wallet")}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <img
                            style={{ height: "20px" }}
                            src={EWallet}
                            alt=""
                          />
                        </Box>
                      </PaymentMethodTitle>
                    </PaymentType>
                    <PaymentContainer>
                      {creatingSession ? (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <CircularProgress size={20} />
                        </Box>
                      ) : (
                        <Box>
                          <TabContext value={payValue}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <TabList
                                onChange={handlePayValueChange}
                                aria-label="E-Wallet Types"
                              >
                                <Tab
                                  sx={
                                    payValue === "1"
                                      ? { fontWeight: "bolder" }
                                      : null
                                  }
                                  label={translate("paycard.googlePay")}
                                  value="1"
                                />
                                <Tab
                                  sx={
                                    payValue === "2"
                                      ? { fontWeight: "bolder" }
                                      : null
                                  }
                                  label={translate("paycard.applePay")}
                                  value="2"
                                />
                              </TabList>
                            </Box>
                            <TabPanel value="1">
                              <GooglePay
                                setCreatingSession={setCreatingSession}
                                sessionId={sessionID}
                                amount={"10"}
                                paymentMetaData={paymentMetaData}
                                scriptURL={scriptURL}
                              />
                            </TabPanel>
                            <TabPanel value="2">
                              <ApplePay
                                setCreatingSession={setCreatingSession}
                                sessionId={sessionID}
                                amount={"10"}
                                paymentMetaData={paymentMetaData}
                                scriptURL={scriptURL}
                              />
                            </TabPanel>
                          </TabContext>
                        </Box>
                      )}
                    </PaymentContainer>
                  </PaymentMethod>
                </CardBox>
                <CardBox>
                  <PaymentMethod
                    expanded={stcExpanded}
                    onChange={(event, isExpanded) => {
                      if (isExpanded) {
                        initSession("stc");
                        setCardExpanded(false);
                        setEWalletExpanded(false);
                      }
                      setSTCExpanded(isExpanded);
                    }}
                  >
                    <PaymentType
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <PaymentMethodTitle>
                        <Typography sx={{ fontWeight: "bold" }}>
                        {translate("checkout.stc")}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <img style={{ height: "20px" }} src={stc} alt="" />
                        </Box>
                      </PaymentMethodTitle>
                    </PaymentType>
                    <PaymentContainer>
                      {creatingSession ? (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <CircularProgress size={20} />
                        </Box>
                      ) : (
                        <Typography>
                          <STCPay />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <PayButton
                              sx={{
                                backgroundColor: "#50008C",
                                "&:hover": {
                                  backgroundColor: "#50008C",
                                  opacity: "0.8",
                                },
                              }}
                              size="small"
                              variant="contained"
                              disableElevation
                              onClick={() => {
                                payUsingSTCPay();
                              }}
                            >
                              {payingSTCPay ? (
                                <CircularProgress
                                  sx={{ color: "#fff" }}
                                  size={20}
                                />
                              ) : (
                                <>
                                  <PaymentIcon />{" "}
                                  <ButtonText>{translate("paycard.payNow")}</ButtonText>
                                </>
                              )}
                            </PayButton>
                          </Box>
                        </Typography>
                      )}
                    </PaymentContainer>
                  </PaymentMethod>
                </CardBox>
                <CardBox>
                  <PaymentMethod disabled>
                    <PaymentType
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <PaymentMethodTitle>{translate("checkout.tabby")}</PaymentMethodTitle>
                    </PaymentType>
                    <PaymentContainer>
                      <Typography>
                        Tabby will be here.
                      </Typography>
                    </PaymentContainer>
                  </PaymentMethod>
                </CardBox>
              </Box>
            </Box>
            <Typography sx={{fontSize: "16px", fontWeight: "600"}}>
              {translate("checkout.c-tag")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "20px"
              }}
            >
              <PayButton
                size="small"
                color="primary"
                variant="contained"
                disableElevation
                onClick={() => placeOrder()}
              >
                {loading ? (
                  <>
                    <MoonLoader color="#fff" size={20} />
                  </>
                ) : (
                    <>
                    <PaymentIcon /> <ButtonText>{translate("checkout.proceed")}</ButtonText>
                    </>
                )}
              </PayButton>
              <PayButton
                startIcon={<CartIcon sx={{ marginRight: "8px" }} />}
                size="small"
                color="primary"
                variant="contained"
                disableElevation
                onClick={() => {
                  navigate('/')
                }}
              >
                <ButtonText>{translate("checkout.shopmore")}</ButtonText>
              </PayButton>
            </Box>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(Box)(({ theme }) => ({
  margin: "40px ",
  [theme.breakpoints.down("sm")]: {
    margin: "10px",
  },
}));

const CardHeading = styled(Typography)(() => ({
  fontSize: "20px",
  fontWeight: "bold",
  padding: "0px 2px ",
  marginBottom: "10px",
}));

const CardBox = styled(Box)(() => ({
  // padding: "10px",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  border: " 1px solid #DDDDDD",
  marginBottom: "10px",
  borderRadius: "0px",
  padding: "0px",
}));

const ButtonText = styled(Typography)(() => ({
  marginRight: "5px",
  fontWeight: "bold",
  fontSize: "14px",
}));

const PayButton = styled(Button)(() => ({
  color: "#fff",
  fontWeight: "bolder",
  textTransform: "captialize",
  marginTop: "2rem",
  padding: "4px 24px",
  width: "200px",
  height: "40px",
  borderRadius: "0px",
  gap: "10px"
}));

const PaymentMethod = styled(Accordion)(() => ({
  width: "100%",
  boxShadow: "none",
}));

const PaymentType = styled(AccordionSummary)(() => ({
  fontSize: "14px",
  fontWeight: "600",
}));

const PaymentContainer = styled(AccordionDetails)(() => ({}));

const PaymentMethodTitle = styled(Typography)(() => ({
  fontWeight: "bold",
  display: "flex",
  alignItems: "center",
  gap: "10px",
}));

export default MyFatoorahGateway;
