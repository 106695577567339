import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const root = {
  backgroundColor: (theme) => theme.palette.primary.main,
  color: (theme) => theme.palette.primary.light,
  borderRadius: "0px",
  boxShadow: "none",
  height: "40px",
  padding: "10px",
  fontSize: "12px",

  "&:hover": {
    backgroundColor: (theme) => theme.palette.primary.light,
    border: (theme) => `1px solid ${theme.palette.primary.main}`,
    color: (theme) => theme.palette.primary.main,
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
  },

  "&:disabled": {
    backgroundColor: "#C8C8C8",
    color: "#fff",
    border: "none",
  },

  "@media (max-width: 576px)": {
    fontSize: "10px",
    width: "auto",
    padding: "8px",
  },
};

const primaryOutline = {
  borderRadius: "5px",
  textAlign: "center",
  background: (theme) => theme.palette.primary.contrastText,
  color: (theme) => theme.palette.primary.main,
  letterSpacing: 0.28,
  fontWeight: "700",
  fontSize: "12px",
  padding: "5px 20px",
  border: (theme) => `1px solid ${theme.palette.primary.main}`,
  "&:disabled": {
    color: "#fff",
    backgroundColor: "#C8C8C8",
    border: "none",
  },
  "&:hover": {
    color: (theme) => theme.palette.primary.main,
  },
  textTransform: "capitalize",

  "@media screen and (max-width: 425px)": {
    fontSize: "11px",
  },
};

const linkButton = {
  borderRadius: "5px",
  textAlign: "center",
  color: (theme) => theme.palette.primary.main,
  letterSpacing: 0.28,
  fontWeight: "600",
  fontSize: "10px",
  textTransform: "capitalize",
  transition: "all 0.3s ease-in-out",
  cursor: "pointer",

  "&:hover": {
    background: "none",
  },
};

const PrimaryButton = (props) => {
  const styles = props.sx || {};
  const href = props.href;
  return (
    <>
      {props.link ? (
        <Link href={props.link}>
          <Button
            disableElevation
            {...props}
            sx={[root, styles]}
            onSubmit={props.onSubmit}
            onClick={props.onClick}
          >
            {props.children}
          </Button>
        </Link>
      ) : (
        <Button
          disableElevation
          {...props}
          sx={[root, styles]}
          onSubmit={props.onSubmit}
          onClick={props.onClick}
          href={href}
        >
          {props.children}
        </Button>
      )}
    </>
  );
};

const PrimaryOutlineButton = (props) => {
  const styles = props.sx || {};
  return (
    <>
      {props.link ? (
        <Link href={props.link}>
          <Button
            disableElevation
            {...props}
            sx={[primaryOutline, styles]}
            onSubmit={props.onSubmit}
            onClick={props.onClick}
          >
            {props.children}
          </Button>
        </Link>
      ) : (
        <Button
          disableElevation
          {...props}
          sx={[primaryOutline, styles]}
          onSubmit={props.onSubmit}
          onClick={props.onClick}
        >
          {props.children}
        </Button>
      )}
    </>
  );
};

const LinkButton = (props) => {
  const styles = props.sx || {};
  return (
    <Button
      disableElevation
      {...props}
      sx={[linkButton, styles]}
      onSubmit={props.onSubmit}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

export { PrimaryButton, PrimaryOutlineButton, LinkButton };
