// React Imports
import { useNavigate } from "react-router-dom";
// MUI
import { Box, Button, Typography } from "@mui/material";
import CustomModal from "../../components/CustomModal";
import { thousandSeparator } from "../../utils";
import { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { useTranslation } from "../../contexts/LanguageContext";
import { PrimaryButton } from "../../components/PrimaryButton";

const InActiveCartModal = ({ modalOpen, setModalOpen, handleResetTimer }) => {
	const navigate = useNavigate();
	const { translate, getLanguage, getDirection } = useTranslation();
	const language = getLanguage();
	const [products, setProducts] = useState([]);

	let user = window.localStorage.getItem("username");

	useEffect(() => {
		const cartProducts = localStorage.getItem("cartProducts");
		if (cartProducts) {
			setProducts(JSON.parse(cartProducts));
		}
	}, []);

	return (
		<>
			<CustomModal
				open={modalOpen}
				sx={{
					width: "600px",
				}}
			>
				<Box dir={getDirection()}
					sx={{
						padding: "20px",
					}}
				>	
					{language === "ar" ?
						<h3>العميل المحترم</h3>
						:
						<h3>Respected {user ? user : "User"}</h3>
					}
					<Box
						sx={{
							marginTop: "10px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Typography
							sx={{
								fontSize: "13px",
							}}
						>
							{translate("cart.products")}
						</Typography>
						<Typography
							sx={{
								fontSize: "13px",
							}}
						>
							{translate("cart.missout")}
						</Typography>
					</Box>
					<Box
						sx={{
							margin: "20px 0",
							display: "flex",
							alignItems: "center",
							gap: "20px",
							backgroundColor: (theme) => theme.palette.primary.main,
							padding: "10px",
							color: "#fff",
							borderRadius: "4px",
							"@media (max-width: 576px)": {
								overflowX: "auto",
							},
						}}
					>
						<Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
							{translate("cart.image")}
						</Typography>
						<Typography
							sx={{
								fontSize: "14px",
								fontWeight: 600,
								width: "175px",
								"@media (max-width: 576px)": {
									minWidth: "175px",
								},
							}}
						>
							{translate("cart.name")}
						</Typography>
						<Typography
							sx={{
								fontSize: "14px",
								fontWeight: 600,
								width: "60px",
								"@media (max-width: 576px)": {
									minWidth: "60px",
								},
							}}
						>
							{translate("cart.price")}
						</Typography>
						<Typography
							sx={{
								fontSize: "14px",
								fontWeight: 600,
								width: "60px",
								"@media (max-width: 576px)": {
									minWidth: "60px",
								},
							}}
						>
							{translate("cart.qty")}
						</Typography>
						<Typography
							sx={{
								fontSize: "14px",
								fontWeight: 600,
								width: "60px",
								marginRight: "20px",
								"@media (max-width: 576px)": {
									minWidth: "60px",
								},
							}}
						>
							{translate("cart.ttl")}
						</Typography>
					</Box>
					{products.map((product) => {
						return (
							<Box
								key={product?.id}
								sx={{
									margin: "10px 0 0",
									display: "flex",
									alignItems: "center",
									gap: "20px",
									borderBottom: "1px solid #e0e0e0",
									padding: "0 0 10px 0",
									"@media (max-width: 576px)": {
										overflowX: "auto",
									},
								}}
							>
								<Box>
									<img
										src={product?.images[0]?.image}
										alt="product"
										style={{
											width: "45px",
											height: "45px",
											objectFit: "contain",
										}}
									/>
								</Box>
								<Typography
									sx={{
										width: "180px",
										display: "flex",
										flexWrap: "wrap",
										"@media (max-width: 576px)": {
											minWidth: "180px",
										},
									}}
								>
									{language === "ar"
									? product?.commons?.ar?.productName
									: product?.commons?.en?.productName}
								</Typography>
								<Typography
									sx={{
										width: "60px",
										"@media (max-width: 576px)": {
											minWidth: "60px",
										},
									}}
								>
									{thousandSeparator(Number(product.discounted_price))}
								</Typography>
								<Typography
									sx={{
										width: "60px",
										display: "flex",
										justifyContent: "center",
										"@media (max-width: 576px)": {
											minWidth: "60px",
										},
									}}
								>
									{product.quantity}
								</Typography>
								<Typography
									sx={{
										width: "60px",
										marginRight: "20px",
										"@media (max-width: 576px)": {
											minWidth: "60px",
										},
									}}
								>
									{thousandSeparator(
										Number(product.discounted_price * product.quantity)
									)}
								</Typography>
							</Box>
						);
					})}
					<Box
						sx={{
							margin: "40px 0 10px 0",
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Button
							variant="outlined"
							sx={{
								height: "40px",
							}}
							onClick={() => {
								handleResetTimer();
								setModalOpen(false)
							}}
						>
							{translate("cart.continue")}
						</Button>
						<PrimaryButton
							onClick={() => {
								handleResetTimer();
								if (user) {
									navigate("/checkout");
								} else {
									navigate("/cart");
								}
							}}
							variant="contained"
							sx={{
                                height: "40px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                                borderRadius: "4px",
                                "&:hover": {
                                  backgroundColor: (theme) => theme.palette.primary.main,
                                  color: (theme) => theme.palette.primary.light,
                                  borderRight: (theme) =>
                                    `1px solid ${theme.palette.primary.main}`,
                                },
                              }}
						>
							<Box>
								<DoneIcon
									style={{
										fontSize: "20px",
										marginTop: "6px",
									}}
								/>
							</Box>
							{translate("cart.proceed")}
						</PrimaryButton>
					</Box>
				</Box>
			</CustomModal>
		</>
	);
};

export default InActiveCartModal;
