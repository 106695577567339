// Redux Toolkit Imports
import { configureStore } from "@reduxjs/toolkit";
// Custom Imports
import authReducer from "./auth/authSlice.js";
import cartReducer from "./cart/cartSlice.js";
import themeReducer from "./theme/themeSlice.js";
import settingReducer from "./settings/settingSlice.js";
import { apiSlice } from "./api/apiSlice.js";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer, // For API Integration

    auth: authReducer,
    cart: cartReducer,
    settings: settingReducer,
    theme: themeReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});
