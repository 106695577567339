import { styled } from "@mui/system";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// Contexts Import
import { useCart } from "../contexts/CartContext";
import { useTranslation } from "../contexts/LanguageContext";
// MUI Components Import
import {
  Box,
  Button,
  ButtonGroup,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
// Components Import
import Footer from "./components/Footer/Footer";
import LoginNotifModal from "./components/Modals/LoginNotifModal";
import AppBar from "./components/Navbar";
// Icons import
import EmptyCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { toast } from "react-toastify";
// import DiscountBanner from "../components/DiscountBanner";First Order Discount
import useTypedSelector from "../hooks/useTypedSelector";
import { selectedUser } from "../redux/auth/authSlice";
import { selectedSetting } from "../redux/settings/settingSlice";
import { SubHeading } from "../components/Heading";

const Cart = () => {
  const navigate = useNavigate();
  const {
    cartProducts,
    incrementById,
    decrementById,
    calculateTotalPrice,
    actualTotal,
    emptyCart,
    isOrderLimitExceeded,
  } = useCart();
  const totalPrice = calculateTotalPrice();
  const actualPrice = actualTotal();
  const { translate, getLanguage, getDirection } = useTranslation();
  const language = getLanguage();
  const loginUser = useTypedSelector(selectedUser);
  const settings = useTypedSelector(selectedSetting);

  const [loginModalOpen, setLoginModalOpen] = useState(false);

  const handleIncrement = (id) => {
    const product = cartProducts.find((p) => p.id === id);
    if (isOrderLimitExceeded(id)) {
      toast.warning(
        `Order limit (${parseFloat(product.orderLimit).toFixed(
          0
        )}) can not be exceeded!`
      );
      return;
    }
    incrementById(id);
  };

  const handleDecrement = (id) => {
    decrementById(id);
  };

  const isAnyProductBelowMinQty = cartProducts.some(
    (product) => product.quantity < product.minQty
  );

  const checkToken = () => {
    const token = window.localStorage.getItem("mp-user-token");

    if (token === null) {
      setLoginModalOpen(true);
    } else {
      navigate("/checkout");
    }
  };

  const isFirstOrder =
    cartProducts?.length > 0 && loginUser?.is_ordered === false;

  return (
    <>
      <AppBar />
      <Wrapper>
        <LoginNotifModal open={loginModalOpen} setOpen={setLoginModalOpen} />
        <Grid container my={5} gap={"40px"} dir={getDirection()}>
          <Grid item md={7.5}>
            <ProductBox>
              {cartProducts?.length === 0 ? (
                <Typography sx={{ textAlign: "center", padding: "15px" }}>
                  {translate("cart.no")}
                </Typography>
              ) : (
                <>
                  <EmptyCartBtn
                    endIcon={<EmptyCartIcon sx={{ marginRight: "8px" }} />}
                    variant="outlined"
                    color="success"
                    onClick={() => emptyCart()}
                  >
                    {translate("cart.empty")}
                  </EmptyCartBtn>
                  {cartProducts.map((product, index) => {
                    const variant = product?.variants?.find(
                      (v) => v.id === product?.id
                    );
                    return (
                      <CartWrapper key={index} item md={12}>
                        <ImageWrapper>
                          <ImageBox
                            component="img"
                            image={
                              variant?.variantImage || product?.images[0]?.image
                            }
                            alt="image"
                          />
                        </ImageWrapper>
                        <Detailbox>
                          <Heading>
                            {language === "ar"
                              ? product?.commons?.ar?.productName
                              : product?.commons?.en?.productName}
                          </Heading>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <CategoryBox>
                              {product?.productCategory}
                            </CategoryBox>
                            <CategoryBox
                              sx={{
                                backgroundColor: "#6c5ad5",
                                color: "#fff",
                              }}
                            >
                              {product?.product_type_display}
                            </CategoryBox>
                          </Box>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems={"start"}
                              gap={"5px"}
                            >
                              <Heading sx={{ color: "#E92E67" }}>
                                {product.discounted_price === null
                                  ? product.vat_onlinePrice
                                  : product.discounted_price}{" "}
                                {translate(`navbar.SR`)}
                              </Heading>
                              {product?.product_type_display === "Service" ? (
                                <QuantityButtons
                                  size="small"
                                  sx={{ marginTop: "5px" }}
                                  aria-label="small outlined button group"
                                >
                                  <Button
                                    onClick={() => handleDecrement(product.id)}
                                    sx={{
                                        fontWeight: "bold",
                                        color: (theme) =>
                                          theme.palette.primary.main,
                                        "&:hover": {
                                          backgroundColor: "transparent",
                                          color: (theme) =>
                                            theme.palette.primary.main,
                                          borderRight: (theme) =>
                                            `1px solid ${theme.palette.primary.main}`,
                                          borderTop: "none",
                                          borderBottom: "none",
                                          borderLeft: "none",
                                        },
                                      }}
                                  >
                                    {translate("cart.removeService")}
                                  </Button>
                                </QuantityButtons>
                              ) : (
                                <QuantityButtons
                                  size="small"
                                  sx={{ marginTop: "5px", width: "130px" }}
                                  aria-label="small outlined button group"
                                >
                                  <Button
                                  sx={{
                                    fontWeight: "bold",
                                    color: (theme) =>
                                      theme.palette.primary.main,
                                    "&:hover": {
                                      backgroundColor: "transparent",
                                      color: (theme) =>
                                        theme.palette.primary.main,
                                      borderRight: (theme) =>
                                        `1px solid ${theme.palette.primary.main}`,
                                      borderTop: "none",
                                      borderBottom: "none",
                                      borderLeft: "none",
                                    },
                                  }}
                                    onClick={() => handleDecrement(product.id)}
                                  >
                                    -
                                  </Button>
                                  <Button
                                   sx={{
                                    fontWeight: "bold",
                                    color: (theme) =>
                                      theme.palette.primary.main,
                                    "&:hover": {
                                      backgroundColor: "transparent",
                                      color: (theme) =>
                                        theme.palette.primary.main,
                                      borderLeft: (theme) =>
                                        `1px solid ${theme.palette.primary.main}`,
                                      borderTop: "none",
                                      borderBottom: "none",
                                    },
                                  }}
                                   >
                                    {product.quantity}
                                  </Button>
                                  <Button
                                  sx={{
                                    fontWeight: "bold",
                                    color: (theme) =>
                                      theme.palette.primary.main,
                                    "&:hover": {
                                      backgroundColor: "transparent",
                                      color: (theme) =>
                                        theme.palette.primary.main,
                                      borderLeft: (theme) =>
                                        `1px solid ${theme.palette.primary.main}`,
                                      borderTop: "none",
                                      borderBottom: "none",
                                      borderRight: "none",
                                    },
                                  }}
                                    onClick={() => handleIncrement(product.id)}
                                  >
                                    +
                                  </Button>
                                </QuantityButtons>
                              )}
                            </Box>
                          </Box>
                          {product.quantity < product.minQty && (
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "12px",
                                color: "red",
                                marginTop: "5px",
                                fontWeight: "700",
                              }}
                            >
                              {translate("cart.belowLimit")} {parseFloat(product.minQty).toFixed(0)}
                            </Typography>
                          )}

                          {variant?.variantCombination && (
                            <Box>
                              <Typography
                                sx={{ fontSize: "14px", color: "#5F6C72" }}
                              >
                                {translate("cart.variant")}
                              </Typography>
                              <Button
                                sx={{
                                  border: "1px solid #00A9BF",
                                  textTransform: "none",
                                  padding: "2px 12px",
                                  backgroundColor: "#00A9BF",
                                  color: "#fff",
                                  margin: "5px 0 10px 0",
                                  cursor: "auto",
                                  "&:hover": {
                                    backgroundColor: "#00A9BF",
                                    color: "#fff",
                                  },
                                }}
                              >
                                {variant?.variantCombination}
                              </Button>
                            </Box>
                          )}
                        </Detailbox>
                      </CartWrapper>
                    );
                  })}
                </>
              )}
            </ProductBox>
          </Grid>
          <Grid
            item
            md={4}
            display={"flex"}
            flexDirection={"column"}
            gap={"20px"}
          >
            {/* CartTotal */}
            <Box
              item
              md={10}
              sx={{
                padding: "10px",
                borderRadius: "10px",
                boxShadow: ".2px .2px 2px 0px rgba(255,255,255,.4)",
                // height: "300px",
                border: " 1px solid #DDDDDD",
              }}
            >
              <SubHeading
                sx={{
                  padding: "15px 10px",
                }}
              >
                {translate("cart.total")}
              </SubHeading>

              <Box
                display={"flex"}
                flexDirection={"column"}
                marginTop={"10px"}
              >
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography sx={{ fontSize: "14px", color: "#5F6C72" }}>
                    {translate("cart.sub")}
                  </Typography>
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                    {parseFloat(totalPrice || 0).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} {translate(`navbar.SR`)}
                  </Typography>
                </Box>
                <Box
                  mt={"8px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <Typography sx={{ fontSize: "14px", color: "#5F6C72" }}>
                    {translate("cart.dis")}
                  </Typography>
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                    0
                  </Typography>
                </Box>

                {/* Discount */}
                {isFirstOrder && (
                  <>
                    <Box
                      sx={{
                        background: "#029444",
                        backgroundImage:
                          "linear-gradient(315deg, #029444 0%, #d3d3d3 74%)",
                        marginTop: "15px",
                        padding: "5px 10px",
                      }}
                    >
                      <Box
                        sx={{
                          color: "#fff",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "#000",
                            fontWeight: 700,
                          }}
                        >
                          {translate(`cart.firstDiscount`)}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "23px",
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          {Number(settings?.first_time_discount || 0)}%{" "}
                          <span
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            {translate(`cart.off`)}
                          </span>
                        </Typography>{" "}
                      </Box>
                    </Box>
                  </>
                )}
                {/* Discount */}

                {isFirstOrder && (
                  <Box
                    mt={"20px"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    borderTop={"1px solid #DDDDDD"}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "black",
                        fontWeight: "bold",
                        mt: "10px",
                      }}
                    >
                      {translate(`cart.tot`)}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        mt: "10px",
                        color: "red",
                        textDecoration: "line-through",
                      }}
                    >
                      {actualPrice} {translate(`navbar.SR`)}
                    </Typography>
                  </Box>
                )}
                <Box
                  mt={isFirstOrder ? "0" : "20px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  borderTop={isFirstOrder ? "" : "1px solid #DDDDDD"}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "black",
                      fontWeight: "bold",
                      mt: "10px",
                    }}
                  >
                    {isFirstOrder ? (
                      translate("cart.totalDiscount")
                    ) : (
                      <>{translate("cart.tot")}</>
                    )}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: "bold", mt: "10px" }}
                  >
                    {parseFloat(totalPrice || 0).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    {translate(`navbar.SR`)}
                  </Typography>
                </Box>
              </Box>
              <Box
                mt={2}
                mb={4}
                gap={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  size="small"
                  style={{
                    textTransform: "none",
                    padding: "4px 24px",
                    width: "100%",
                    height: "40px",
                    borderRadius: "5px",
                    fontWeight: "bolder",
                    fontSize: "16px",
                    marginTop: "20px"
                  }}
                  color="secondary"
                  variant="contained"
                  onClick={() => checkToken()}
                  disabled={isAnyProductBelowMinQty}
                >
                  {translate("cart.proceed")}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Wrapper>
      <Footer />
    </>
  );
};

// Styled Components
const Wrapper = styled(Box)(({ theme }) => ({
  margin: "40px",
  [theme.breakpoints.down("sm")]: {
    margin: "5px",
    padding: "10px",
  },
}));

const QuantityButtons = styled(ButtonGroup)(({ theme }) => ({
    border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "5px",
  width: "auto",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .MuiButton-root": {
    border: "none",
  },
  "& .MuiButton-root:first-child": {
    border: "none",
  },
  "& .MuiButton-root:last-child": {},
  [theme.breakpoints.down("sm")]: {
    width: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "25px",
    gap: "2px",
    "& .MuiButtonBase-root": {
      padding: "8px",
      minWidth: "unset",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    },
  },
}));

const EmptyCartBtn = styled(Button)(() => ({
  width: "auto",
  alignSelf: "flex-end",
  fontWeight: "bolder",
  textTransform: "uppercase",
  borderRadius: "5px",
}));

const CartWrapper = styled(Grid)(({ theme }) => ({
  padding: "20px",
  borderRadius: "0px",
  boxShadow: "rgba(99, 99, 99, 0.1) 0px 2px 8px 0px",
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  [theme.breakpoints.down("sm")]: {
    padding: "10px",
  },
}));

// const CartTotal = styled(Grid)(({ theme }) => ({
//   padding: "10px",
//   borderRadius: "10px",
//   boxShadow: ".2px .2px 2px 0px rgba(255,255,255,.4)",
//   // height: "300px",
//   border: " 1px solid #DDDDDD",
//   [theme.breakpoints.down("sm")]: {
//     width: "290px",
//   },
// }));

const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "16px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  width: "100px",
  height: "100px",
  [theme.breakpoints.down("sm")]: {
    width: "50px",
  },
}));

const ProductBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "30px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    display: "flex",
    gap: "10px",
    width: "100%",
  },
}));

const Detailbox = styled(Box)({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
});

const ImageBox = styled(CardMedia)(({ theme }) => ({
  width: "100px",
  height: "100px",
  objectFit: "contain",
  borderRadius: "20px",
  [theme.breakpoints.down("sm")]: {
    width: "50px",
    borderRadius: "10px",
    objectFit: "cover",
  },
}));

const CategoryBox = styled(Box)(() => ({
  backgroundColor: "#80c7ff",
  color: " #007ad9 ",
  fontWeight: "bolder",
  fontSize: "14px",
  width: "fit-content",
  padding: "2px 8px",
  borderRadius: "5px",
  margin: "5px 0px",
}));

export default Cart;
