import { useEffect, useState } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Container, Box } from "@mui/material";
import ProductCard from "./Cards/ProductCard";
import { useTranslation } from "../../contexts/LanguageContext";

import { baseUrl } from "../../constants/api";
import MoonLoaderComponent from "../../components/Loader/MoonLoaderComponent";
import { Heading } from "../../components/Heading";

function Recomendation({ engHeading, arbHeading, bgColor }) {
	const token = window.localStorage.getItem("mp-user-token");

	const [loading, setLoading] = useState(true);
	const [recommendation, setRecommendation] = useState([]);
	const { language } = useTranslation();

	const fetchRecommendation = () => {
		setLoading(true);
		axios
			.post(
				`${baseUrl}recommendations`,
				{ token: token },
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then((response) => {
				setRecommendation(response.data);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchRecommendation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return recommendation.length === 0 ? (
		<></>
	) : (
		<Wrapper maxWidth={false} sx={{backgroundColor: `${bgColor}`}}>
            <Heading>{language === "ar" ? arbHeading : engHeading}</Heading>
			{loading ? (
				<Box
					sx={{ display: "flex", justifyCgCoent: "center", padding: "20px 0" }}
				>
					<MoonLoaderComponent color="#000" size={20} />
				</Box>
			) : (
				<RecommendedProductsWrapper
					sx={
						recommendation?.length < 6
							? { justifyContent: "center" }
							: { justifyContent: "flex-start" }
					}
				>
					{recommendation.map((product, index) => (
						<ProductCard key={index} product={product} id={product.prodId} />
					))}
				</RecommendedProductsWrapper>
			)}
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					padding: "20px",
				}}
			></Box>
		</Wrapper>
	);
}

// Styled Components

const Wrapper = styled(Container)(() => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	gap: "30px",
	padding: "40px 0",
	width: "100%",
	margin: "30px auto",
	backgroundColor: "#ae7766",
}));

const RecommendedProductsWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	height: "auto",
	overflowX: "auto", // Ensure horizontal scroll for the container
	overflowY: "hidden", // Hide vertical overflow
	padding: "0px",
	"& > *": {
		flex: "0 0 auto",
		minWidth: "230px",
	},

	"&::-webkit-scrollbar": {
		height: "8px", // Adjust the height of the scrollbar
	},
	"&::-webkit-scrollbar-thumb": {
		background: "#888", // Color of the scrollbar thumb
		borderRadius: "10px", // Rounded corners for the scrollbar thumb
	},
	"&::-webkit-scrollbar-thumb:hover": {
		background: "#555", // Color when hovering over the scrollbar thumb
	},

	[theme.breakpoints.down("sm")]: {
		justifyContent: "flex-start",
		flexWrap: "nowrap",
		overflowX: "auto",
		"& > *": {
			flex: "0 0 auto",
			minWidth: "230px",
		},
	},
}));

export default Recomendation;
