import { useEffect, useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";

// Components Import
import MyFatoorahGateway from './PaymentProviders/MyFatoorah/MyFatoorah'
import PayfortGateway from './PaymentProviders/PayFort/PayFort'

import axios from "axios";
import { Box, Button, styled, Typography } from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CartIcon from '@mui/icons-material/ShoppingCartOutlined';
import { MoonLoader } from "react-spinners";
import { useCart } from "../contexts/CartContext";
import { useTranslation } from "../contexts/LanguageContext";
import { baseUrl } from "../constants/api";
import { setUser } from "../redux/auth/authSlice";
import Navbar from "./components/Navbar";

// payment-gateway

function Payment() {
    const token = window.localStorage.getItem("mp-user-token");

    const { emptyCart } = useCart();

    const { translate } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [payload, /*setPayload*/] = useState(location?.state?.payload)
    const [/*orderId*/, setOrderId] = useState(location?.state?.ordId)


    const [paymentGateway, setPaymentGateway] = useState(null);
    const [loading, setLoading] = useState(true);

    const getPaymentGateway = async () => {
        axios
            .get(`${baseUrl}payment-gateway`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                setPaymentGateway(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getPaymentGateway()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const placeOrder = async () => {
        setLoading(true)
        const response = await axios.post(`${baseUrl}order`, payload,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        )

        if (response.status === 201) {
            const data = await response.data;
            const loggedInUser = JSON.parse(localStorage.getItem("user"));

            const updatedUser = {
                ...loggedInUser,
                metadata: {
                    ...loggedInUser.metadata,
                    is_ordered: data?.is_ordered,
                },
            };

            localStorage.setItem("user", JSON.stringify(updatedUser));
            dispatch(setUser(updatedUser));
            setOrderId(data?.order_id)
            emptyCart();
            setLoading(false)

            navigate(`/profile/orderdetail/${data?.order_id}`);

            return data?.order_id;
        } else {
            return null
        }
    }

    return (
        <>
            <Navbar />
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80vh",
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {paymentGateway?.payment_provider === 2 ? (
                        <MyFatoorahGateway />
                    ) : paymentGateway?.payment_provider === 1 ? (
                        <PayfortGateway />
                    ) :
                        <Box sx={{ height: "80vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                                {translate("checkout.c-tag")}
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    gap: "20px"
                                }}
                            >
                                <PayButton
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    disableElevation
                                    onClick={() => placeOrder()}
                                >
                                    {loading ? (
                                        <>
                                            <MoonLoader color="#fff" size={20} />
                                        </>
                                    ) : (
                                        <>
                                            <PaymentIcon /> <ButtonText>{translate("checkout.proceed")}</ButtonText>
                                        </>
                                    )}
                                </PayButton>
                                <PayButton
                                    startIcon={<CartIcon sx={{ marginRight: "8px" }} />}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    disableElevation
                                    onClick={() => {
                                        navigate('/')
                                    }}
                                >
                                    <ButtonText>{translate("checkout.shopmore")}</ButtonText>
                                </PayButton>
                            </Box>
                        </Box>
                    }
                </>
            )}
        </>
    );
}

const ButtonText = styled(Typography)(() => ({
    marginRight: "5px",
    fontWeight: "bold",
    fontSize: "14px",
}));

const PayButton = styled(Button)(() => ({
    color: "#fff",
    fontWeight: "bolder",
    textTransform: "captialize",
    marginTop: "2rem",
    padding: "4px 24px",
    width: "200px",
    height: "40px",
    borderRadius: "0px",
    gap: "10px"
}));

export default Payment;
