import { apiSlice } from "./apiSlice.js";

export const themeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchThemeSettings: builder.query({
      query: () => {
        return {
          url: "get/styles",
          method: "GET",
        };
      },
    }),
  }),
});

export const { useFetchThemeSettingsQuery } = themeApiSlice;
