import { Box, Container, Grid, Typography, styled } from "@mui/material";

// Assets Import
import { useTranslation } from "../../contexts/LanguageContext";
import useTypedSelector from "../../hooks/useTypedSelector";
import { selectedThemeSettings } from "../../redux/theme/themeSlice";

function Features() {
    const { getDirection, getLanguage } = useTranslation();
    const language = getLanguage();
    const themeSetting = useTypedSelector(selectedThemeSettings);

    const features = themeSetting?.features;

    return (
        <Wrapper>
            <Grid container justifyContent="space-between">
                {features?.map((service, index) => (
                    <ServiceCard item key={index} xs={12} sm={4} md={3} lg={2} dir={getDirection()}>
                        <ImgBox>
                            <img
                                src={service?.image}
                                style={{
                                    height: "47%",
                                    width: "47%",
                                    objectFit: "contain",
                                }}
                                alt={service?.en_title}
                            />
                        </ImgBox>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: "700",
                                    color: "#454545",
                                    textAlign: "center",
                                }}
                            >
                                {language === "en" ? service?.en_title : service?.ar_title}
                            </Typography>
                            <Typography
                                sx={{ fontSize: "11px", color: "#61676A", textAlign: "center" }}
                            >
                                {language === "en"
                                    ? service?.en_description
                                    : service?.ar_description}
                            </Typography>
                        </Box>
                    </ServiceCard>
                ))}
            </Grid>
        </Wrapper>
    );
}

const Wrapper = styled(Container)({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0",
    margin: "40px auto",
    gap: "20px",
});

const ServiceCard = styled(Grid)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
});

const ImgBox = styled(Grid)({
    height: "100px",
    width: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: "#e3e2de",
});

export default Features;
