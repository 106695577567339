import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
// MUI Components Import
import { Container, Box } from "@mui/material";
import { useTranslation } from "../../contexts/LanguageContext";
import { useFetchBestSellersQuery } from "../../redux/api/landingPageApiSlice";
import ProductCard from "./Cards/ProductCard";
import { Heading } from "../../components/Heading"
import MoonLoaderComponent from "../../components/Loader/MoonLoaderComponent"


function BestSeller({ engHeading, arbHeading, bgColor }) {
	const [bestSellers, setBestSellers] = useState([]);
	const { getLanguage } = useTranslation();
	const language = getLanguage();


	// todo: GET BEST SELLERS API CALL
	const { data, isLoading } = useFetchBestSellersQuery({});

	useEffect(() => {
		if (data) {
			setBestSellers(data);
		}
	}, [data]);

	return bestSellers.length === 0 ? (
		<></>
        ) : (
		<Wrapper maxWidth={false} sx={{backgroundColor: `${bgColor}`}}>
            <Heading>{language === "ar" ? arbHeading : engHeading}</Heading>
			{isLoading ? (
				<Box
					sx={{ display: "flex", justifyContent: "center", padding: "20px 0" }}
				>
					<MoonLoaderComponent color="#000" size={20} />
				</Box>
			) : (
				<BestProductsWrapper
					sx={
						bestSellers?.length < 6
							? { justifyContent: "center" }
							: { justifyContent: "flex-start" }
					}
				>
					{bestSellers.map((product, index) => (
						<ProductCard key={index} product={product} id={product.prodId} />
					))}
				</BestProductsWrapper>
			)}
		</Wrapper>
	);
}

// Styled Components

const Wrapper = styled(Container)(() => ({
	display: "flex",
	flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
	gap: "30px",
	padding: "50px 0",
	margin: "30px 0",
	width: "100%",
}));

const BestProductsWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	height: "auto",
	overflowX: "auto", 
	overflowY: "hidden",
	padding: "0px",
	"& > *": {
		flex: "0 0 auto",
		minWidth: "230px",
	},
	"&::-webkit-scrollbar": {
		height: "8px", 
	},
	"&::-webkit-scrollbar-thumb": {
		background: "#888", 
		borderRadius: "10px",
	},
	"&::-webkit-scrollbar-thumb:hover": {
		background: "#555", 
	},
	[theme.breakpoints.down("sm")]: {
		justifyContent: "flex-start",
		flexWrap: "nowrap",
		overflowX: "auto",
		"& > *": {
			flex: "0 0 auto",
			minWidth: "230px",
		},
	},
}));

export default BestSeller;
