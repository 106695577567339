import { useState } from "react";
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Modal, Box, Grid, Typography, Button, TableContainer, TableCell, Paper, Table, TableHead, TableRow, TableBody, Checkbox } from "@mui/material";

import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../../../constants/api";
import { thousandSeparator } from "../../../utils";
import { MoonLoader } from "react-spinners";
import { useTranslation } from "../../../contexts/LanguageContext";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "50%",
	backgroundColor: "background.paper",
	boxShadow: 24,
	p: 4,
	borderRadius: "8px",
	display: "flex",
	flexDirection: "column",
	justifyContent: "start",
	gap: "12px",
};

function RefundModal({ open, setOpen, orderDetail }) {

	const token = window.localStorage.getItem("mp-user-token");

	const { translate, getDirection } = useTranslation();


	const [remarks, setRemarks] = useState(null)
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [loading, setLoading] = useState(false)

	const handleProductClick = (product) => {
		const productIndex = selectedProducts.findIndex(p => p.prodId === product.product.prodId);

		if (productIndex !== -1) {
			setSelectedProducts(selectedProducts.filter(p => p.prodId !== product.product.prodId));
		} else {
			setSelectedProducts([...selectedProducts, { prodId: product.product.prodId, opdId: product.opdId }]);
		}
	};

	const RequestRefund = () => {
		setLoading(true)
		if (remarks === null){
			toast.error("Please add your remarks!");
		}
		axios
			.post(
				`${baseUrl}refund/create`,
				{
					reason: remarks,
					ordId: orderDetail?.order?.ordId,
					vendorId: orderDetail?.order?.vendor,
					products: selectedProducts
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Token ${token}`,
					},
				}
			)
			.then(() => {
				toast.success("Request submitted successfully!");
				setLoading(false)
				setRemarks('')
				setSelectedProducts([])
				setOpen(false);
			})
			.catch(() => {
				setLoading(false)
				toast.error("Request couldn't be submitted!");
			});
	};

	return (
		<>
			<Modal open={open} onClose={() => setOpen(false)}>
				<Box sx={style} dir={getDirection()}>
					<Box sx={{ width: "100%", borderBottom: "1px solid #a59e9e" }}>
						<Heading>{translate("refund.create")}</Heading>
					</Box>
					<Grid container>
						<Typography>{translate("refund.select-product")}</Typography>
						<Grid item md={12}>
							<TableWrapper component={Paper}>
								<Table>
									<TableHead style={{ backgroundColor: "#DDDDDD" }}>
										<TableRow>
											<TableHeadings>
											{translate("refund.select")}
											</TableHeadings>
											<TableHeadings>
											{translate("refund.image")}
											</TableHeadings>
											<TableHeadings>
											{translate("refund.name")}
											</TableHeadings>
											<TableHeadings>
											{translate("refund.cat")} / {translate("refund.subcat")}
											</TableHeadings>
											<TableHeadings>
											{translate("refund.brand")}
											</TableHeadings>
											<TableHeadings>
											{translate("refund.quantity")}
											</TableHeadings>
											<TableHeadings>
											{translate("refund.total")}
											</TableHeadings>
										</TableRow>
									</TableHead>
									<TableBody>
										{orderDetail?.products?.map((item, index) => {
											const isSelected = selectedProducts.some(p => p.prodId === item.product.prodId);
											return (
												<TableRow key={index}>
													<TableContent>
														<Checkbox
															checked={isSelected}
															onClick={() => handleProductClick(item)}
															value="check"
														/>
													</TableContent>
													<TableContent>
														<img
															src={
																item?.variant?.variantImage ||
																item?.product.images[0].image
															}
															alt="product_image"
															style={{
																width: "50px",
																height: "50px",
																borderRadius: "5px",
																marginRight: "8px",
															}}
														/>
													</TableContent>
													<TableContent>
														{item?.product.commons.en.productName},{" "}
														{item?.product.commons.ar.productName}
													</TableContent>
													<TableContent>
														{item?.product.productCategory} /{" "}
														{item?.product.productSubcategory}
													</TableContent>
													<TableContent>
														{item?.product.brand}
													</TableContent>
													<TableContent sx={{ fontWeight: "700" }}>
														{item?.quantity} items
													</TableContent>
													<TableContent>
														{thousandSeparator(
															Number(item?.amount).toFixed(
																2
															) * item?.quantity
														)}{" "}
														{translate(`navbar.SR`)}
													</TableContent>
												</TableRow>
											)
										})}
									</TableBody>
								</Table>
							</TableWrapper>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item md={12}>
							<StyledTextarea
								sx={{ padding: "10px" }}
								value={remarks}
								onChange={(e) => setRemarks(e.currentTarget.value)}
								placeholder={translate("refund.placeholder")}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid
							item
							sm={12}
							md={12}
							display={"flex"}
							justifyContent={"end"}
							gap={"15px"}
						>
							<BottomButton
								variant="outlined"
								color="secondary"
								onClick={() => setOpen(false)}
							>
								{translate("refund.cancel")}
							</BottomButton>
							<BottomButton
								onClick={() => RequestRefund()}
								variant="contained"
								color="secondary"
							>
								{loading ? (
									<>
										<MoonLoader color="#fff" size={20} />
									</>
									) : (
									translate("refund.submit")
								)}
							</BottomButton>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</>
	);
}

const BottomButton = styled(Button)(({ theme }) => ({
	height: "40px",
	width: "20%",
	mt: "23px",
	borderRadius: "20px",
	[theme.breakpoints.down("sm")]: {
		width: "45%",
	},
}));

const Heading = styled(Typography)(() => ({
	fontSize: "18px",
	fontWeight: "600",
}));

const StyledTextarea = styled("textarea")(() => ({
	background: "#fff",
	borderRadius: "8px",
	fontSize: "16px",
	margin: "8px 0",
	height: "100px",
	width: "100%",
	border: "1px solid #C9CFD2",
}));

const TableWrapper = styled(TableContainer)(() => ({
	height: "auto",
	overflow: "auto",
	border: "none",
	boxShadow: "none",
}));

const TableHeadings = styled(TableCell)(() => ({
	fontWeight: "600",
	fontSize: "14px",
	lineHeight: "16px",
	color: "black",
	background: "#F2F4F5",
	textAlign: "center",
}));

const TableContent = styled(TableCell)(() => ({
	fontWeight: "400",
	fontSize: "12px",
	lineHeight: "16px",
	color: "#71747D",
	border: "none",
	textAlign: "center",
}));

export default RefundModal;
