import { useEffect } from "react";
import useTypedSelector from "../hooks/useTypedSelector";
import { selectedSetting } from "../redux/settings/settingSlice";

const GoogleAnalytics = () => {
  const settings = useTypedSelector(selectedSetting);

  useEffect(() => {
    // Load the Google Analytics script
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${settings?.google_analytics}`;
    document.head.appendChild(script1);

    // Initialize Google Analytics
    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${settings?.google_analytics}');
    `;
    document.head.appendChild(script2);
  }, [settings]);

  return null;
};

export default GoogleAnalytics;
