import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Login from "./Pages/Entry/Login";
import Register from "./Pages/Entry/Register";
import ForgotPassword from "./Pages/Entry/ForgotPassword";
import ResetPassword from "./Pages/Entry/ResetPassword";

import LandingPage from "./Pages/LandingPage";
import ProductDetail from "./Pages/ProductDetail";
import Cart from "./Pages/Cart";
import Checkout from "./Pages/Checkout";
import ProductsListing from "./Pages/ProductsListing";

import Profile from "./Pages/components/Profile/Profile";
import ProfileDetails from "./Pages/components/Profile/components/ProfileDetails";
import OrderDetail from "./Pages/components/Profile/components/OrderDetail";
import Addresses from "./Pages/components/Profile/components/Addresses";
import Cards from "./Pages/components/Profile/components/Cards";
import SearchResults from "./Pages/SearchResults";
import Payment from "./Pages/Payment";
import AuthGuard from "./Auth";
import OrderHistory from "./Pages/components/Profile/components/OrderHistory";
import ScrollToTop from "./ScrollToTop";
import ContactUs from "./Pages/ContactUs";
import RefundHistory from "./Pages/components/Profile/components/RefundHistory";
import DynamicPage from "./Pages/DynamicPage";
import Blogs from "./Pages/Blogs"
import SingleBlog from "./Pages/Blogs/components/SingleBlog"


const Router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <ScrollToTop>
        <Login />
      </ScrollToTop>
    ),
  },
  {
    path: "/register",
    element: (
      <ScrollToTop>
        <Register />
      </ScrollToTop>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <ScrollToTop>
        <ForgotPassword />
      </ScrollToTop>
    ),
  },
  {
    path: "/reset-password",
    element: (
      <ScrollToTop>
        <ResetPassword />
      </ScrollToTop>
    ),
  },
  {
    path: "/",
    element: (
      <ScrollToTop>
        <LandingPage />
      </ScrollToTop>
    ),
  },
  {
    path: "/:page_id",
    element: (
      <ScrollToTop>
        <DynamicPage />
      </ScrollToTop>
    ),
  },
  {
    path: "/contact-us",
    element: (
      <ScrollToTop>
        <ContactUs />
      </ScrollToTop>
    ),
  },
  {
    path: "/blogs",
    element: (
      <ScrollToTop>
        <Blogs />
      </ScrollToTop>
    ),
  },
  {
    path: "/blogs/:slug",
    element: (
      <ScrollToTop>
        <SingleBlog />
      </ScrollToTop>
    ),
  },
  {
    path: "/category/:catId",
    element: (
      <ScrollToTop>
        <ProductsListing />
      </ScrollToTop>
    ),
  },
  {
    path: "/productdetail/:id",
    element: (
      <ScrollToTop>
        <ProductDetail />
      </ScrollToTop>
    ),
  },
  {
    path: "/cart",
    element: (
      <ScrollToTop>
        <Cart />
      </ScrollToTop>
    ),
  },
  {
    path: "/checkout",
    element: (
      <ScrollToTop>
        <AuthGuard>
          <Checkout />
        </AuthGuard>
      </ScrollToTop>
    ),
  },
  {
    path: "/payment",
    element: (
      <ScrollToTop>
        <AuthGuard>
          <Payment />
        </AuthGuard>
      </ScrollToTop>
    ),
  },
  {
    path: "/profile",
    element: (
      <ScrollToTop>
        <AuthGuard>
          <Profile />
        </AuthGuard>
      </ScrollToTop>
    ),
    children: [
      {
        path: "",
        element: (
          <ScrollToTop>
            <AuthGuard>
              <ProfileDetails />
            </AuthGuard>
          </ScrollToTop>
        ),
      },
      {
        path: "order-history",
        element: (
          <ScrollToTop>
            <AuthGuard>
              <OrderHistory />
            </AuthGuard>
          </ScrollToTop>
        ),
      },
      {
        path: "orderdetail/:id",
        element: (
          <ScrollToTop>
            <AuthGuard>
              <OrderDetail />
            </AuthGuard>
          </ScrollToTop>
        ),
      },
      {
        path: "refund-history",
        element: (
          <ScrollToTop>
            <AuthGuard>
              <RefundHistory />
            </AuthGuard>
          </ScrollToTop>
        ),
      },
      {
        path: "addresses",
        element: (
          <ScrollToTop>
            <AuthGuard>
              <Addresses />
            </AuthGuard>
          </ScrollToTop>
        ),
      },
      {
        path: "cards",
        element: (
          <ScrollToTop>
            <AuthGuard>
              <Cards />
            </AuthGuard>
          </ScrollToTop>
        ),
      },
    ],
  },
  {
    path: "search/:query",
    element: (
      <ScrollToTop>
        <SearchResults />
      </ScrollToTop>
    ),
  },
]);

const RootComponent = () => {
  return <RouterProvider router={Router} />;
};

export default RootComponent;
