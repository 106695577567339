import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../contexts/LanguageContext";

import { styled, Box, Container, Grid, Typography } from "@mui/material";

import { useFetchCategoriesQuery } from "../../redux/api/landingPageApiSlice";
import MoonLoaderComponent from "../../components/Loader/MoonLoaderComponent";
import { Heading } from "../../components/Heading";

function CategoryList({ engHeading, arbHeading, bgColor }) {
    const { getLanguage, getDirection } = useTranslation();
    const language = getLanguage();

    const navigate = useNavigate();

    const [category, setCategory] = useState([]);

    // todo: GET CATEGORIES DATA API CALL
    const { data, isLoading } = useFetchCategoriesQuery({});

    useEffect(() => {
        if (data) {
            setCategory(data);
        }
    }, [data]);

    return (
        <Wrapper>
            <CatTopbar dir={getDirection()}>
                <Heading>{language === "ar" ? arbHeading : engHeading}</Heading>
            </CatTopbar>

            {isLoading ? (
                <Box
                    sx={{ display: "flex", justifyContent: "center", padding: "20px 0" }}
                >
                    <MoonLoaderComponent color="#000" size={20} />
                </Box>
            ) : (
                <CategoryWrapper sx={{ backgroundColor: `${bgColor}` }}>
                    {category.map((cat, index) => (
                        <CatWrapper
                            item
                            key={index}
                            xs={12}
                            sm={4}
                            md={2}
                            lg={2}
                            onClick={() => navigate(`/category/${cat.catId}`)}
                        >
                            <img
                                src={cat.image}
                                style={{
                                    height: "80px",
                                    width: "80px",
                                    borderTopRightRadius: "10px",
                                    borderTopLeftRadius: "10px",
                                }}
                                alt={cat.title}
                            />
                            <CatTitle>{language === "ar" ? cat.arb_name : cat.name}</CatTitle>
                        </CatWrapper>
                    ))}
                </CategoryWrapper>
            )}
        </Wrapper>
    );
}

const Wrapper = styled(Container)(() => ({
    margin: "40px auto",
}));

const CatTopbar = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    borderBottom: "1px solid #00000029",
}));

const CategoryWrapper = styled(Container)(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "20px",
    padding: "30px 0",
    overflowX: "auto",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
        display: "none",
    },
}));

const CatWrapper = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "cover",
    padding: "8px",
    gap: "8px",
    cursor: "pointer",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    borderRadius: "5px",
    transition: "all 0.1s ease",
    "&:hover": {
        boxShadow: "none",
        outline: "1px solid #2c2c",
    },
}));

const CatTitle = styled(Typography)(() => ({
    fontSize: "14px",
    color: "#000000",
    cursor: "pointer",
    marginBottom: "8px",
}));

export default CategoryList;
