// MUI Components Import
import { Container } from "@mui/material";

// Components Import
import Navbar from "./components/Navbar";
import Cookies from "./components/Dropdowns/Cookies";
import BannersCarousel from "./components/Carousels/BannersCarousel";
import Partners from "./components/Partners";
import BestSeller from "./components/BestSeller";
import Recomendation from "./components/Recomendation";
import DiscountBanner from "./components/DiscountBanner";
import CategoryList from "./components/CategoryList";
import Services from "./components/Services";
import TestimonialsCarousel from "./components/Carousels/TestimonialsCarousel";
import Features from "./components/Features";
import Footer from "./components/Footer/Footer";

import useTypedSelector from "../hooks/useTypedSelector";
import { selectedThemeSettings } from "../redux/theme/themeSlice"

const componentMap = {
    BannersCarousel,
    Partners,
    BestSeller,
    Recomendation,
    DiscountBanner,
    CategoryList,
    Services,
    TestimonialsCarousel,
    Features,
};

const LandingPage = () => {

    const themeSetting = useTypedSelector(selectedThemeSettings);

	return (
		<Container maxWidth={false} disableGutters={true}>
			<Navbar />
			<Cookies />
			<div
                style={{
                width: "100%",
                backgroundColor: "#FBF5FF",
                paddingTop: "40px",
                }}
            >
                {themeSetting?.arrangements?.map((section, index) => {
                    const Component = componentMap[section.id];
                    if (!Component) return null;
                    return <Component key={index} {...section.state} />;
                })}
                <Footer />
            </div>
		</Container>
	);
};

export default LandingPage;
