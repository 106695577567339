import { Typography } from "@mui/material";
import useTypedSelector from "../../hooks/useTypedSelector";
import { selectedThemeSettings } from "../../redux/theme/themeSlice";

const Heading = ({ children, sx }) => {
  const styles = sx || {};

  const themeSetting = useTypedSelector(selectedThemeSettings);

  const root = {
    fontSize: `${themeSetting?.h1_font_size}px` || "22px",
    fontWeight: "700",
    // color: "orange",
    color: "#000",

    "@media (max-width: 576px)": {
      fontSize: "23px",
    },
  };

  return <Typography sx={{ ...root, ...styles }}>{children}</Typography>;
};

const SubHeading = ({ children, sx }) => {
  const styles = sx || {};

  const themeSetting = useTypedSelector(selectedThemeSettings);

  const subRoot = {
    fontSize: `${themeSetting?.h2_font_size}px` || "15px",
    fontWeight: "500",
    // color: "#0378a5",
    color: "#000",

    "@media (max-width: 576px)": {
      fontSize: "19px",
    },
  };

  return <Typography sx={{ ...subRoot, ...styles }}>{children}</Typography>;
};

const TertiaryHeading = ({ children, sx }) => {
  const styles = sx || {};

  const themeSetting = useTypedSelector(selectedThemeSettings);

  const tertiaryRoot = {
    fontSize: `${themeSetting?.h3_font_size}px` || "12px",
    fontWeight: "400",
    // color: "#cea82a",
    color: "#000",

    "@media (max-width: 576px)": {
      fontSize: "16px",
    },
  };

  return (
    <Typography sx={{ ...tertiaryRoot, ...styles }}>{children}</Typography>
  );
};

const Paragraph = ({ children, sx }) => {
  const styles = sx || {};

  const themeSetting = useTypedSelector(selectedThemeSettings);

  const paragraphRoot = {
    fontSize: `${themeSetting?.p_font_size}px` || "14px",
    color: "#000",

    "@media (max-width: 576px)": {
      fontSize: "12px",
    },
  };

  return (
    <Typography sx={{ ...paragraphRoot, ...styles }}>{children}</Typography>
  );
};

export { Heading, SubHeading, TertiaryHeading, Paragraph };
