import { styled } from "@mui/material/styles";
// MUI Components Import
import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "../../contexts/LanguageContext";
import { useGetServicesQuery } from "../../redux/api/serviceCategoryApiSlice";
import ProductCard from "./Cards/ProductCard";
import MoonLoaderComponent from "../../components/Loader/MoonLoaderComponent";

function Services({ engHeading, arbHeading, bgColor }) {
    const { getLanguage } = useTranslation();
    const language = getLanguage();

    // todo: GET SERVICES API CALL
    const { data: getServices, isLoading } = useGetServicesQuery({});

    return getServices?.results?.length === 0 ? (
		<></>
        ) : (
        <Wrapper maxWidth={false} sx={{ backgroundColor: `${bgColor}` }}>
            <Heading>{language === "ar" ? arbHeading : engHeading}</Heading>
            {isLoading ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "20px 0",
                    }}
                >
                    <MoonLoaderComponent color="#000" size={20} />
                </Box>
            ) : (
                <ServiceProductsWrapper
                    sx={
                        getServices?.results?.length < 6
                            ? { justifyContent: "center" }
                            : { justifyContent: "flex-start" }
                    }
                >
                    {getServices?.results?.map((product, index) => (
                        <ProductCard
                            key={index}
                            product={product}
                            id={product.prodId}
                            service={true}
                        />
                    ))}
                </ServiceProductsWrapper>
            )}
        </Wrapper>
    );
}

// Styled Components

const Wrapper = styled(Container)(() => ({
	display: "flex",
	flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
	gap: "30px",
	padding: "50px 0",
	margin: "30px 0",
	width: "100%",
}));

const Heading = styled(Typography)({
    fontSize: "2.2rem",
    fontWeight: "700",
    color: "#0a0a33",
});

const ServiceProductsWrapper = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "auto",
    overflowX: "auto", // Ensure horizontal scroll for the container
    overflowY: "hidden", // Hide vertical overflow
    padding: "0px",
    "& > *": {
        flex: "0 0 auto",
        minWidth: "230px",
    },
    "&::-webkit-scrollbar": {
        height: "8px", // Adjust the height of the scrollbar
    },
    "&::-webkit-scrollbar-thumb": {
        background: "#888", // Color of the scrollbar thumb
        borderRadius: "10px", // Rounded corners for the scrollbar thumb
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: "#555", // Color when hovering over the scrollbar thumb
    },
}));

export default Services;
