// Redux Toolkit Imports
import { createSlice } from "@reduxjs/toolkit";

const settingSlice = createSlice({
  name: "setting",
  initialState: {},

  reducers: {
    setSetting(state, action) {
      state.setting = action.payload;
    },
  },
});

export const { setSetting } = settingSlice.actions;
export default settingSlice.reducer;

export const selectedSetting = (state) => state.settings?.setting;
