// Import Marquee
import Marquee from "react-fast-marquee";

// MUI Components Import
import { Container, Box, styled } from "@mui/material";

import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../constants/api";

function Partners() {

	const token = window.localStorage.getItem("mp-user-token");


	const [partners, setPartners] = useState([])

	const fetchPartnerLogos = () => {
		axios.get(`${baseUrl}brand_logos`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Token ${token}`,
				},
			}
		)
			.then((response) => {
				setPartners(response.data)
			})
			.catch((err) => {
				console.log("Error", err)
			})
	}

	useEffect(() => {
		fetchPartnerLogos()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
    
	return (
		<Wrapper maxWidth={false}>
			<Marquee
				container
				sx={{
					overflowX: "auto",
					scrollbarWidth: "none",
					display: "flex",
					alignItems: "center",
					flexWrap: "nowrap",
					gap: "30px",
				}}
			>
				{partners.length > 0 &&
					partners?.map((partner, index) => (
						<ImgBox key={index}>
							<img
								src={partner.image}
								alt={`Partner ${index}`}
								style={{
									width: "150px",
									height: "auto",
									objectFit: "cover",
									marginRight: "20px",
								}}
							/>
						</ImgBox>
					))}
			</Marquee>
		</Wrapper>
	);
}

const Wrapper = styled(Container)({
	width: "100%",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	gap: "12px",
	height: "150px",
	padding: "0 10px",
});

const ImgBox = styled(Box)({
	flex: "0 0 auto",
	maxWidth: "calc(33.33% - 12px)",
	height: "auto",
	paddingLeft: "15px",
});

export default Partners;
